import { ReactComponent as InfoIcon } from './toast/info.svg'
import { ReactComponent as SuccessIcon } from './toast/success.svg'
import { ReactComponent as WarnIcon } from './toast/warn.svg'
import { ReactComponent as ErrorIcon } from './toast/error.svg'

import { ReactComponent as DashboardIcon } from './navigation/dashboard.svg'
import { ReactComponent as StartupDiscoveryIcon } from './navigation/discovery.svg'
import { ReactComponent as MessageIcon } from './navigation/message.svg'
import { ReactComponent as EventIcon } from './navigation/event.svg'
import { ReactComponent as FileIcon } from './navigation/file.svg'
import { ReactComponent as NetworkIcon } from './navigation/network.svg'
import { ReactComponent as NotificationIcon } from './navigation/notification.svg'
import { ReactComponent as ProfileIcon } from './navigation/profile.svg'
import { ReactComponent as SettingIcon } from './navigation/setting.svg'
import { ReactComponent as TeamIcon } from './navigation/team.svg'
import { ReactComponent as BreadcrumbBackIcon } from './navigation/back.svg'
import { ReactComponent as DropdownNavIcon } from './navigation/dropdown.svg'
import { ReactComponent as LogoutIcon } from './navigation/logout.svg'
import { ReactComponent as RolesIcon } from './navigation/roles.svg'
import { ReactComponent as FundIcon } from './navigation/fund.svg'
import { ReactComponent as DealFlowIcon } from './navigation/dealflow.svg'

import { ReactComponent as AcFlagIcon } from './flags/ac Ascension Island.svg'
import { ReactComponent as AdFlagIcon } from './flags/ad Andorra.svg'
import { ReactComponent as AeFlagIcon } from './flags/ae United Arab Emirates.svg'
import { ReactComponent as AfFlagIcon } from './flags/af Afghanistan.svg'
import { ReactComponent as AgFlagIcon } from './flags/ag Antigua and Barbuda.svg'
import { ReactComponent as AiFlagIcon } from './flags/ai Anguilla.svg'
import { ReactComponent as AlFlagIcon } from './flags/al Albania.svg'
import { ReactComponent as AmFlagIcon } from './flags/am Armenia.svg'
import { ReactComponent as AoFlagIcon } from './flags/ao Angola.svg'
import { ReactComponent as AqFlagIcon } from './flags/aq Antarctica.svg'
import { ReactComponent as ArFlagIcon } from './flags/ar Argentina.svg'
import { ReactComponent as AsFlagIcon } from './flags/as American Samoa.svg'
import { ReactComponent as AtFlagIcon } from './flags/at Austria.svg'
import { ReactComponent as AuFlagIcon } from './flags/au Australia.svg'
import { ReactComponent as AwFlagIcon } from './flags/aw Aruba.svg'
import { ReactComponent as AxFlagIcon } from './flags/ax Aland Islands.svg'
import { ReactComponent as AzFlagIcon } from './flags/az Azerbaijan.svg'
import { ReactComponent as BaFlagIcon } from './flags/ba Bosnia and Herzegovina.svg'
import { ReactComponent as BbFlagIcon } from './flags/bb Barbados.svg'
import { ReactComponent as BdFlagIcon } from './flags/bd Bangladesh.svg'
import { ReactComponent as BeFlagIcon } from './flags/be Belgium.svg'
import { ReactComponent as BfFlagIcon } from './flags/bf Burkina Faso.svg'
import { ReactComponent as BgFlagIcon } from './flags/bg Bulgaria.svg'
import { ReactComponent as BhFlagIcon } from './flags/bh Bahrain.svg'
import { ReactComponent as BiFlagIcon } from './flags/bi Burundi.svg'
import { ReactComponent as BjFlagIcon } from './flags/bj Benin.svg'
import { ReactComponent as BlFlagIcon } from './flags/bl Saint Barthelemy.svg'
import { ReactComponent as BmFlagIcon } from './flags/bm Bermuda.svg'
import { ReactComponent as BnFlagIcon } from './flags/bn Brunei Darussalam.svg'
import { ReactComponent as BoFlagIcon } from './flags/bo Bolivia.svg'
import { ReactComponent as BqFlagIcon } from './flags/bq Bonaire, Sint Eustatius and Saba.svg'
import { ReactComponent as BrFlagIcon } from './flags/br Brazil.svg'
import { ReactComponent as BsFlagIcon } from './flags/bs Bahamas.svg'
import { ReactComponent as BtFlagIcon } from './flags/bt Bhutan.svg'
import { ReactComponent as BvFlagIcon } from './flags/bv Bouvet Island.svg'
import { ReactComponent as BwFlagIcon } from './flags/bw Botswana.svg'
import { ReactComponent as ByFlagIcon } from './flags/by Belarus.svg'
import { ReactComponent as BzFlagIcon } from './flags/bz Belize.svg'
import { ReactComponent as CaFlagIcon } from './flags/ca Canada.svg'
import { ReactComponent as CcFlagIcon } from './flags/cc Cocos (Keeling) Islands.svg'
import { ReactComponent as CdFlagIcon } from './flags/cd Democratic Republic of the Congo.svg'
import { ReactComponent as CeftaFlagIcon } from './flags/cefta Central European Free Trade Agreement.svg'
import { ReactComponent as CfFlagIcon } from './flags/cf Central African Republic.svg'
import { ReactComponent as CgFlagIcon } from './flags/cg Republic of the Congo.svg'
import { ReactComponent as ChFlagIcon } from './flags/ch Switzerland.svg'
import { ReactComponent as CiFlagIcon } from "./flags/ci Cote d'Ivoire.svg"
import { ReactComponent as CkFlagIcon } from './flags/ck Cook Islands.svg'
import { ReactComponent as ClFlagIcon } from './flags/cl Chile.svg'
import { ReactComponent as CmFlagIcon } from './flags/cm Cameroon.svg'
import { ReactComponent as CnFlagIcon } from './flags/cn China.svg'
import { ReactComponent as CoFlagIcon } from './flags/co Colombia.svg'
import { ReactComponent as CpFlagIcon } from './flags/cp Clipperton Island.svg'
import { ReactComponent as CrFlagIcon } from './flags/cr Costa Rica.svg'
import { ReactComponent as CuFlagIcon } from './flags/cu Cuba.svg'
import { ReactComponent as CvFlagIcon } from './flags/cv Cabo Verde.svg'
import { ReactComponent as CwFlagIcon } from './flags/cw Curacao.svg'
import { ReactComponent as CxFlagIcon } from './flags/cx Christmas Island.svg'
import { ReactComponent as CyFlagIcon } from './flags/cy Cyprus.svg'
import { ReactComponent as CzFlagIcon } from './flags/cz Czech Republic.svg'
import { ReactComponent as DeFlagIcon } from './flags/de Germany.svg'
import { ReactComponent as DgFlagIcon } from './flags/dg Diego Garcia.svg'
import { ReactComponent as DjFlagIcon } from './flags/dj Djibouti.svg'
import { ReactComponent as DkFlagIcon } from './flags/dk Denmark.svg'
import { ReactComponent as DmFlagIcon } from './flags/dm Dominica.svg'
import { ReactComponent as DoFlagIcon } from './flags/do Dominican Republic.svg'
import { ReactComponent as DzFlagIcon } from './flags/dz Algeria.svg'
import { ReactComponent as FiFlagIcon } from './flags/fi Finland.svg'
import { ReactComponent as FmFlagIcon } from './flags/fm Federated States of Micronesia.svg'
import { ReactComponent as FjFlagIcon } from './flags/fj Fiji.svg'
import { ReactComponent as FkFlagIcon } from './flags/fk Falkland Islands.svg'
import { ReactComponent as FoFlagIcon } from './flags/fo Faroe Islands.svg'
import { ReactComponent as FrFlagIcon } from './flags/fr France.svg'
import { ReactComponent as EaFlagIcon } from './flags/ea Ceuta & Melilla.svg'
import { ReactComponent as EcFlagIcon } from './flags/ec Ecuador.svg'
import { ReactComponent as EeFlagIcon } from './flags/ee Estonia.svg'
import { ReactComponent as EgFlagIcon } from './flags/eg Egypt.svg'
import { ReactComponent as EhFlagIcon } from './flags/eh Western Sahara.svg'
import { ReactComponent as ErFlagIcon } from './flags/er Eritrea.svg'
import { ReactComponent as EsFlagIcon } from './flags/es Spain.svg'
import { ReactComponent as Es_ctFlagIcon } from './flags/es-ct Catalonia.svg'
import { ReactComponent as Es_gaFlagIcon } from './flags/es-ga Galicia.svg'
import { ReactComponent as EtFlagIcon } from './flags/et Ethiopia.svg'
import { ReactComponent as EuFlagIcon } from './flags/eu Europe.svg'
import { ReactComponent as GaFlagIcon } from './flags/ga Gabon.svg'
import { ReactComponent as GbFlagIcon } from './flags/gb United Kingdom uk.svg'
import { ReactComponent as Gb_engFlagIcon } from './flags/gb-eng England.svg'
import { ReactComponent as Gb_nirFlagIcon } from './flags/gb-nir Northern Ireland.svg'
import { ReactComponent as Gb_sctFlagIcon } from './flags/gb-sct Scotland.svg'
import { ReactComponent as Gb_wlsFlagIcon } from './flags/gb-wls Wales.svg'
import { ReactComponent as GdFlagIcon } from './flags/gd Grenada.svg'
import { ReactComponent as GeFlagIcon } from './flags/ge Georgia.svg'
import { ReactComponent as GfFlagIcon } from './flags/gf French Guiana.svg'
import { ReactComponent as GgFlagIcon } from './flags/gg Guernsey.svg'
import { ReactComponent as GhFlagIcon } from './flags/gh Ghana.svg'
import { ReactComponent as GiFlagIcon } from './flags/gi Gibraltar.svg'
import { ReactComponent as GlFlagIcon } from './flags/gl Greenland.svg'
import { ReactComponent as GmFlagIcon } from './flags/gm Gambia.svg'
import { ReactComponent as GnFlagIcon } from './flags/gn Guinea.svg'
import { ReactComponent as GpFlagIcon } from './flags/gp Guadeloupe.svg'
import { ReactComponent as GqFlagIcon } from './flags/gq Equatorial Guinea.svg'
import { ReactComponent as GrFlagIcon } from './flags/gr Greece.svg'
import { ReactComponent as GsFlagIcon } from './flags/gs South Georgia and the South Sandwich Islands.svg'
import { ReactComponent as GtFlagIcon } from './flags/gt Guatemala.svg'
import { ReactComponent as GuFlagIcon } from './flags/gu Guam.svg'
import { ReactComponent as GwFlagIcon } from './flags/gw Guinea-Bissau.svg'
import { ReactComponent as GyFlagIcon } from './flags/gy Guyana.svg'
import { ReactComponent as HkFlagIcon } from './flags/hk Hong Kong.svg'
import { ReactComponent as HmFlagIcon } from './flags/hm Heard Island and McDonald Islands.svg'
import { ReactComponent as HnFlagIcon } from './flags/hn Honduras.svg'
import { ReactComponent as HrFlagIcon } from './flags/hr Croatia.svg'
import { ReactComponent as HtFlagIcon } from './flags/ht Haiti.svg'
import { ReactComponent as HuFlagIcon } from './flags/hu Hungary.svg'
import { ReactComponent as IcFlagIcon } from './flags/ic Canary Islands.svg'
import { ReactComponent as IdFlagIcon } from './flags/id Indonesia.svg'
import { ReactComponent as IeFlagIcon } from './flags/ie Ireland.svg'
import { ReactComponent as IlFlagIcon } from './flags/il Israel.svg'
import { ReactComponent as ImFlagIcon } from './flags/im Isle of Man.svg'
import { ReactComponent as InFlagIcon } from './flags/in India.svg'
import { ReactComponent as IoFlagIcon } from './flags/io British Indian Ocean Territory.svg'
import { ReactComponent as IqFlagIcon } from './flags/iq Iraq.svg'
import { ReactComponent as IrFlagIcon } from './flags/ir Iran.svg'
import { ReactComponent as IsFlagIcon } from './flags/is Iceland.svg'
import { ReactComponent as ItFlagIcon } from './flags/it Italy.svg'
import { ReactComponent as JeFlagIcon } from './flags/je Jersey.svg'
import { ReactComponent as JmFlagIcon } from './flags/jm Jamaica.svg'
import { ReactComponent as JoFlagIcon } from './flags/jo Jordan.svg'
import { ReactComponent as JpFlagIcon } from './flags/jp Japan.svg'
import { ReactComponent as KeFlagIcon } from './flags/ke Kenya.svg'
import { ReactComponent as KgFlagIcon } from './flags/kg Kyrgyzstan.svg'
import { ReactComponent as KhFlagIcon } from './flags/kh Cambodia.svg'
import { ReactComponent as KiFlagIcon } from './flags/ki Kiribati.svg'
import { ReactComponent as KmFlagIcon } from './flags/km Comoros.svg'
import { ReactComponent as KnFlagIcon } from './flags/kn Saint Kitts and Nevis.svg'
import { ReactComponent as KrFlagIcon } from './flags/kr South Korea.svg'
import { ReactComponent as KwFlagIcon } from './flags/kw Kuwait.svg'
import { ReactComponent as KyFlagIcon } from './flags/ky Cayman Islands.svg'
import { ReactComponent as KzFlagIcon } from './flags/kz Kazakhstan.svg'
import { ReactComponent as LaFlagIcon } from './flags/la Laos.svg'
import { ReactComponent as LbFlagIcon } from './flags/lb Lebanon.svg'
import { ReactComponent as LcFlagIcon } from './flags/lc Saint Lucia.svg'
import { ReactComponent as LiFlagIcon } from './flags/li Liechtenstein.svg'
import { ReactComponent as LkFlagIcon } from './flags/lk Sri Lanka.svg'
import { ReactComponent as LrFlagIcon } from './flags/lr Liberia.svg'
import { ReactComponent as LsFlagIcon } from './flags/ls Lesotho.svg'
import { ReactComponent as LtFlagIcon } from './flags/lt Lithuania.svg'
import { ReactComponent as LuFlagIcon } from './flags/lu Luxembourg.svg'
import { ReactComponent as LvFlagIcon } from './flags/lv Latvia.svg'
import { ReactComponent as LyFlagIcon } from './flags/ly Libya.svg'
import { ReactComponent as MaFlagIcon } from './flags/ma Morocco.svg'
import { ReactComponent as McFlagIcon } from './flags/mc Monaco.svg'
import { ReactComponent as MdFlagIcon } from './flags/md Moldova.svg'
import { ReactComponent as MeFlagIcon } from './flags/me Montenegro.svg'
import { ReactComponent as MfFlagIcon } from './flags/mf Saint Martin.svg'
import { ReactComponent as MgFlagIcon } from './flags/mg Madagascar.svg'
import { ReactComponent as MhFlagIcon } from './flags/mh Marshall Islands.svg'
import { ReactComponent as MkFlagIcon } from './flags/mk North Macedonia.svg'
import { ReactComponent as MlFlagIcon } from './flags/ml Mali.svg'
import { ReactComponent as MmFlagIcon } from './flags/mm Myanmar.svg'
import { ReactComponent as MnFlagIcon } from './flags/mn Mongolia.svg'
import { ReactComponent as MpFlagIcon } from './flags/mp Northern Mariana Islands.svg'
import { ReactComponent as MqFlagIcon } from './flags/mq Martinique.svg'
import { ReactComponent as MrFlagIcon } from './flags/mr Mauritania.svg'
import { ReactComponent as MsFlagIcon } from './flags/ms Montserrat.svg'
import { ReactComponent as MtFlagIcon } from './flags/mt Malta.svg'
import { ReactComponent as MuFlagIcon } from './flags/mu Mauritius.svg'
import { ReactComponent as MvFlagIcon } from './flags/mv Maldives.svg'
import { ReactComponent as MwFlagIcon } from './flags/mw Malawi.svg'
import { ReactComponent as MxFlagIcon } from './flags/mx Mexico.svg'
import { ReactComponent as MyFlagIcon } from './flags/my Malaysia.svg'
import { ReactComponent as MzFlagIcon } from './flags/mz Mozambique.svg'
import { ReactComponent as NaFlagIcon } from './flags/na Namibia.svg'
import { ReactComponent as NcFlagIcon } from './flags/nc New Caledonia.svg'
import { ReactComponent as NeFlagIcon } from './flags/ne Niger.svg'
import { ReactComponent as NfFlagIcon } from './flags/nf Norfolk Island.svg'
import { ReactComponent as NgFlagIcon } from './flags/ng Nigeria.svg'
import { ReactComponent as NiFlagIcon } from './flags/ni Nicaragua.svg'
import { ReactComponent as NlFlagIcon } from './flags/nl Netherlands.svg'
import { ReactComponent as NoFlagIcon } from './flags/no Norway.svg'
import { ReactComponent as NpFlagIcon } from './flags/np Nepal.svg'
import { ReactComponent as NrFlagIcon } from './flags/nr Nauru.svg'
import { ReactComponent as NuFlagIcon } from './flags/nu Niue.svg'
import { ReactComponent as NzFlagIcon } from './flags/nz New Zealand.svg'
import { ReactComponent as OmFlagIcon } from './flags/om Oman.svg'
import { ReactComponent as PaFlagIcon } from './flags/pa Panama.svg'
import { ReactComponent as PeFlagIcon } from './flags/pe Peru.svg'
import { ReactComponent as PfFlagIcon } from './flags/pf French Polynesia.svg'
import { ReactComponent as PgFlagIcon } from './flags/pg Papua New Guinea.svg'
import { ReactComponent as PhFlagIcon } from './flags/ph Philippines.svg'
import { ReactComponent as PkFlagIcon } from './flags/pk Pakistan.svg'
import { ReactComponent as PlFlagIcon } from './flags/pl Poland.svg'
import { ReactComponent as PmFlagIcon } from './flags/pm Saint Pierre and Miquelon.svg'
import { ReactComponent as PnFlagIcon } from './flags/pn Pitcairn.svg'
import { ReactComponent as PrFlagIcon } from './flags/pr Puerto Rico.svg'
import { ReactComponent as PsFlagIcon } from './flags/ps State of Palestine.svg'
import { ReactComponent as PtFlagIcon } from './flags/pt Portugal.svg'
import { ReactComponent as PwFlagIcon } from './flags/pw Palau.svg'
import { ReactComponent as PyFlagIcon } from './flags/py Paraguay.svg'
import { ReactComponent as QaFlagIcon } from './flags/qa Qatar.svg'
import { ReactComponent as ReFlagIcon } from './flags/re Reunion.svg'
import { ReactComponent as RoFlagIcon } from './flags/ro Romania.svg'
import { ReactComponent as RsFlagIcon } from './flags/rs Serbia.svg'
import { ReactComponent as RuFlagIcon } from './flags/ru Russia.svg'
import { ReactComponent as RwFlagIcon } from './flags/rw Rwanda.svg'
import { ReactComponent as SaFlagIcon } from './flags/sa Saudi Arabia.svg'
import { ReactComponent as SbFlagIcon } from './flags/sb Solomon Islands.svg'
import { ReactComponent as ScFlagIcon } from './flags/sc Seychelles.svg'
import { ReactComponent as SdFlagIcon } from './flags/sd Sudan.svg'
import { ReactComponent as SeFlagIcon } from './flags/se Sweden.svg'
import { ReactComponent as SgFlagIcon } from './flags/sg Singapore.svg'
import { ReactComponent as ShFlagIcon } from './flags/sh Saint Helena, Ascension and Tristan da Cunha.svg'
import { ReactComponent as SiFlagIcon } from './flags/si Slovenia.svg'
import { ReactComponent as SjFlagIcon } from './flags/sj Svalbard and Jan Mayen.svg'
import { ReactComponent as SkFlagIcon } from './flags/sk Slovakia.svg'
import { ReactComponent as SlFlagIcon } from './flags/sl Sierra Leone.svg'
import { ReactComponent as SmFlagIcon } from './flags/sm San Marino.svg'
import { ReactComponent as SnFlagIcon } from './flags/sn Senegal.svg'
import { ReactComponent as SoFlagIcon } from './flags/so Somalia.svg'
import { ReactComponent as SrFlagIcon } from './flags/sr Suriname.svg'
import { ReactComponent as SsFlagIcon } from './flags/ss South Sudan.svg'
import { ReactComponent as StFlagIcon } from './flags/st Sao Tome and Principe.svg'
import { ReactComponent as SvFlagIcon } from './flags/sv El Salvador.svg'
import { ReactComponent as SxFlagIcon } from './flags/sx Sint Maarten.svg'
import { ReactComponent as SyFlagIcon } from './flags/sy Syria.svg'
import { ReactComponent as SzFlagIcon } from './flags/sz Eswatini.svg'
import { ReactComponent as TaFlagIcon } from './flags/ta Tristan da Cunha.svg'
import { ReactComponent as TcFlagIcon } from './flags/tc Turks and Caicos Islands.svg'
import { ReactComponent as TdFlagIcon } from './flags/td Chad.svg'
import { ReactComponent as TfFlagIcon } from './flags/tf French Southern Territories.svg'
import { ReactComponent as TgFlagIcon } from './flags/tg Togo.svg'
import { ReactComponent as ThFlagIcon } from './flags/th Thailand.svg'
import { ReactComponent as TjFlagIcon } from './flags/tj Tajikistan.svg'
import { ReactComponent as TkFlagIcon } from './flags/tk Tokelau.svg'
import { ReactComponent as TlFlagIcon } from './flags/tl Timor-Leste.svg'
import { ReactComponent as TmFlagIcon } from './flags/tm Turkmenistan.svg'
import { ReactComponent as TnFlagIcon } from './flags/tn Tunisia.svg'
import { ReactComponent as ToFlagIcon } from './flags/to Tonga.svg'
import { ReactComponent as TrFlagIcon } from './flags/tr Turkey.svg'
import { ReactComponent as TtFlagIcon } from './flags/tt Trinidad and Tobago.svg'
import { ReactComponent as TvFlagIcon } from './flags/tv Tuvalu.svg'
import { ReactComponent as TwFlagIcon } from './flags/tw Taiwan.svg'
import { ReactComponent as TzFlagIcon } from './flags/tz Tanzania.svg'
import { ReactComponent as UaFlagIcon } from './flags/ua Ukraine.svg'
import { ReactComponent as UgFlagIcon } from './flags/ug Uganda.svg'
import { ReactComponent as UmFlagIcon } from './flags/um United States Minor Outlying Islands.svg'
import { ReactComponent as UnFlagIcon } from './flags/un United Nations.svg'
import { ReactComponent as UsFlagIcon } from './flags/us United States of America usa.svg'
import { ReactComponent as UyFlagIcon } from './flags/uy Uruguay.svg'
import { ReactComponent as UzFlagIcon } from './flags/uz Uzbekistan.svg'
import { ReactComponent as VaFlagIcon } from './flags/va Holy See.svg'
import { ReactComponent as VcFlagIcon } from './flags/vc Saint Vincent and the Grenadines.svg'
import { ReactComponent as VeFlagIcon } from './flags/ve Venezuela.svg'
import { ReactComponent as VgFlagIcon } from './flags/vg Virgin Islands (British).svg'
import { ReactComponent as ViFlagIcon } from './flags/vi Virgin Islands (U.S.).svg'
import { ReactComponent as VnFlagIcon } from './flags/vn Vietnam.svg'
import { ReactComponent as VuFlagIcon } from './flags/vu Vanuatu.svg'
import { ReactComponent as WfFlagIcon } from './flags/wf Wallis and Futuna.svg'
import { ReactComponent as WsFlagIcon } from './flags/ws Samoa.svg'
import { ReactComponent as XkFlagIcon } from './flags/xk Kosovo.svg'
import { ReactComponent as XxFlagIcon } from './flags/xx Unknown.svg'
import { ReactComponent as YeFlagIcon } from './flags/ye Yemen.svg'
import { ReactComponent as YtFlagIcon } from './flags/yt Mayotte.svg'
import { ReactComponent as ZaFlagIcon } from './flags/za South Africa.svg'
import { ReactComponent as ZmFlagIcon } from './flags/zm Zambia.svg'
import { ReactComponent as ZwFlagIcon } from './flags/zw Zimbabwe.svg'

import { ReactComponent as EyeOpenIcon } from './auth/eyeopen.svg'
import { ReactComponent as EyeCloseIcon } from './auth/eyeclose.svg'

import { ReactComponent as EmptyAssessmentIcon } from './profile/assessment.svg'
import { ReactComponent as CheckIcon } from './profile/check.svg'
import { ReactComponent as CheckradioIcon } from './profile/checkradio.svg'
import { ReactComponent as UncheckIcon } from './profile/uncheck.svg'
import { ReactComponent as UncheckradioIcon } from './profile/uncheckradio.svg'

import { ReactComponent as CancelIcon } from './utils/cancel.svg'
import { ReactComponent as MenubarIcon } from './utils/menubar.svg'
import { ReactComponent as CheckedIcon } from './utils/checked.svg'
import { ReactComponent as UncheckedIcon } from './utils/unchecked.svg'
import { ReactComponent as VerifyIcon } from './utils/verify.svg'
import { ReactComponent as MoreVertIcon } from './utils/morevert.svg'
import { ReactComponent as MoreHorizIcon } from './utils/morehoriz.svg'
import { ReactComponent as MessageFilledIcon } from './utils/message.svg'
import { ReactComponent as EditFilledIcon } from './utils/edit.svg'
import { ReactComponent as PeopleFilledIcon } from './utils/people.svg'
import { ReactComponent as ConnectedFilledIcon } from './utils/connected.svg'
import { ReactComponent as ChevronDownIcon } from './utils/chevrondown.svg'
import { ReactComponent as ArrowDownIcon } from './utils/arrowdown.svg'
import { ReactComponent as RightArrowIcon } from './utils/rightarrow.svg'
import { ReactComponent as OwnerIcon } from './utils/owner.svg'
import { ReactComponent as GalleryAddIcon } from './utils/galleryadd.svg'
import { ReactComponent as DeleteOutlineIcon } from './utils/delete.svg'
import { ReactComponent as UploadIcon } from './utils/upload.svg'
import { ReactComponent as VideoplayIcon } from './utils/videoplay.svg'
import { ReactComponent as AddsquareIcon } from './utils/addsquare.svg'
import { ReactComponent as DangerIcon } from './utils/danger.svg'
import { ReactComponent as EmptyMessageIcon } from './utils/emptymessage.svg'
import { ReactComponent as EmptyEventsIcon } from './utils/emptyevents.svg'
import { ReactComponent as FileImageIcon } from './utils/file.svg'
import { ReactComponent as CalendarIcon } from './utils/calendar.svg'
import { ReactComponent as CalendarOutlineIcon } from './utils/calendaroutline.svg'
import { ReactComponent as DownloadMediaIcon } from './utils/downloadmedia.svg'
import { ReactComponent as ReadIcon } from './utils/read.svg'
import { ReactComponent as LocationIcon } from './utils/location.svg'
import { ReactComponent as GlobalIcon } from './utils/global.svg'
import { ReactComponent as AwardIcon } from './utils/award.svg'

import { ReactComponent as EditAltIcon } from './utils/editalt.svg'
import { ReactComponent as ArrowRightIcon } from './utils/arrowright.svg'
import { ReactComponent as EmptyDataIcon } from './utils/emptydata.svg'
import { ReactComponent as MoneySendIcon } from './utils/moneySend.svg'
import { ReactComponent as UseraddIcon } from './utils/useradd.svg'
import { ReactComponent as AddSquareIcon } from './utils/addsquare.svg'
import { ReactComponent as UserviewIcon } from './utils/userview.svg'
import { ReactComponent as UserdeleteIcon } from './utils/userdelete.svg'
import { ReactComponent as DirectInboxIcon } from './utils/directinbox.svg'
import { ReactComponent as DeleteFilledIcon } from './utils/deletefilled.svg'
import { ReactComponent as AddcircleIcon } from './utils/addcircle.svg'
import { ReactComponent as GridIcon } from './utils/grid.svg'
import { ReactComponent as EyeOpenFilledIcon } from './utils/smallEye.svg'
import { ReactComponent as FolderFilledIcon } from './utils/folder.svg'
import { ReactComponent as IndeterminateIcon } from './utils/indeterminate.svg'
import { ReactComponent as AddToFundIcon } from './utils/fund.svg'
import { ReactComponent as SettingsIcon } from './utils/settings.svg'

import { ReactComponent as AddStartupIcon } from './portfolio/add_start.svg'
import { ReactComponent as StartupIcon } from './portfolio/startup.svg'
import { ReactComponent as AwardPorflioIcon } from './portfolio/award.svg'
import { ReactComponent as MoneyIcon } from './portfolio/money.svg'
import { ReactComponent as ValueIcon } from './portfolio/value.svg'
import { ReactComponent as FundingIcon } from './portfolio/funding.svg'
import { ReactComponent as TeamInfoIcon } from './portfolio/team.svg'
import { ReactComponent as DollarIcon } from './portfolio/dollar.svg'

const iconComponents = {
  toast: {
    InfoIcon,
    SuccessIcon,
    WarnIcon,
    ErrorIcon,
  },
  auth: {
    EyeCloseIcon,
    EyeOpenIcon,
  },
  profile: {
    EmptyAssessmentIcon,
    CheckIcon,
    UncheckIcon,
    CheckradioIcon,
    UncheckradioIcon,
  },
  util: {
    AddToFundIcon,
    SettingsIcon,
    IndeterminateIcon,
    EyeOpenFilledIcon,
    GridIcon,
    AddcircleIcon,
    FolderFilledIcon,
    DeleteFilledIcon,
    UseraddIcon,
    UserdeleteIcon,
    UserviewIcon,
    DirectInboxIcon,
    AddSquareIcon,
    EditAltIcon,
    CancelIcon,
    MenubarIcon,
    UncheckedIcon,
    CheckedIcon,
    VerifyIcon,
    MoreVertIcon,
    MoreHorizIcon,
    MessageFilledIcon,
    EditFilledIcon,
    PeopleFilledIcon,
    ConnectedFilledIcon,
    ChevronDownIcon,
    ArrowDownIcon,
    RightArrowIcon,
    OwnerIcon,
    GalleryAddIcon,
    DeleteOutlineIcon,
    UploadIcon,
    VideoplayIcon,
    AddsquareIcon,
    DangerIcon,
    EmptyMessageIcon,
    EmptyEventsIcon,
    FileImageIcon,
    CalendarIcon,
    CalendarOutlineIcon,
    DownloadMediaIcon,
    ReadIcon,
    LocationIcon,
    GlobalIcon,
    // DirectInboxIcon,
    AwardIcon,
    EmptyDataIcon,
    ArrowRightIcon,
  },
  portfolio: {
    AddStartupIcon,
    StartupIcon,
    AwardIcon,
    MoneyIcon,
    ValueIcon,
    FundingIcon,
    TeamInfoIcon,
    DollarIcon,
    AwardPorflioIcon,
  },
  navigation: {
    MessageIcon,
    EventIcon,
    FileIcon,
    NetworkIcon,
    NotificationIcon,
    ProfileIcon,
    SettingIcon,
    TeamIcon,
    BreadcrumbBackIcon,
    DropdownNavIcon,
    LogoutIcon,
    DashboardIcon,
    StartupDiscoveryIcon,
    MoneySendIcon,
    RolesIcon,
    FundIcon,
    DealFlowIcon,
  },
  flags: {
    AcFlagIcon,
    AdFlagIcon,
    AeFlagIcon,
    AfFlagIcon,
    AgFlagIcon,
    AiFlagIcon,
    AlFlagIcon,
    AmFlagIcon,
    AoFlagIcon,
    AqFlagIcon,
    ArFlagIcon,
    AsFlagIcon,
    AtFlagIcon,
    AuFlagIcon,
    AwFlagIcon,
    AxFlagIcon,
    AzFlagIcon,
    BaFlagIcon,
    BbFlagIcon,
    BdFlagIcon,
    BeFlagIcon,
    BfFlagIcon,
    BgFlagIcon,
    BhFlagIcon,
    BiFlagIcon,
    BjFlagIcon,
    BlFlagIcon,
    BmFlagIcon,
    BnFlagIcon,
    BoFlagIcon,
    BqFlagIcon,
    BrFlagIcon,
    BsFlagIcon,
    BtFlagIcon,
    BvFlagIcon,
    BwFlagIcon,
    ByFlagIcon,
    BzFlagIcon,
    CaFlagIcon,
    CcFlagIcon,
    CdFlagIcon,
    CeftaFlagIcon,
    CfFlagIcon,
    CgFlagIcon,
    ChFlagIcon,
    CiFlagIcon,
    CkFlagIcon,
    ClFlagIcon,
    CmFlagIcon,
    CnFlagIcon,
    CoFlagIcon,
    CpFlagIcon,
    CrFlagIcon,
    CuFlagIcon,
    CvFlagIcon,
    CwFlagIcon,
    CxFlagIcon,
    CyFlagIcon,
    CzFlagIcon,
    DeFlagIcon,
    DgFlagIcon,
    DjFlagIcon,
    DkFlagIcon,
    DmFlagIcon,
    DoFlagIcon,
    DzFlagIcon,
    EaFlagIcon,
    EcFlagIcon,
    EeFlagIcon,
    EgFlagIcon,
    EhFlagIcon,
    ErFlagIcon,
    EsFlagIcon,
    Es_ctFlagIcon,
    Es_gaFlagIcon,
    EtFlagIcon,
    EuFlagIcon,
    FiFlagIcon,
    FjFlagIcon,
    FkFlagIcon,
    FmFlagIcon,
    FoFlagIcon,
    FrFlagIcon,
    GaFlagIcon,
    GbFlagIcon,
    Gb_engFlagIcon,
    Gb_nirFlagIcon,
    Gb_sctFlagIcon,
    Gb_wlsFlagIcon,
    GdFlagIcon,
    GeFlagIcon,
    GfFlagIcon,
    GgFlagIcon,
    GhFlagIcon,
    GiFlagIcon,
    GlFlagIcon,
    GmFlagIcon,
    GnFlagIcon,
    GpFlagIcon,
    GqFlagIcon,
    GrFlagIcon,
    GsFlagIcon,
    GtFlagIcon,
    GuFlagIcon,
    GwFlagIcon,
    GyFlagIcon,
    HkFlagIcon,
    HmFlagIcon,
    HnFlagIcon,
    HrFlagIcon,
    HtFlagIcon,
    HuFlagIcon,
    IcFlagIcon,
    IdFlagIcon,
    IeFlagIcon,
    IlFlagIcon,
    ImFlagIcon,
    InFlagIcon,
    IoFlagIcon,
    IqFlagIcon,
    IrFlagIcon,
    IsFlagIcon,
    ItFlagIcon,
    JeFlagIcon,
    JmFlagIcon,
    JoFlagIcon,
    JpFlagIcon,
    KeFlagIcon,
    KgFlagIcon,
    KhFlagIcon,
    KiFlagIcon,
    KmFlagIcon,
    KnFlagIcon,
    KrFlagIcon,
    KwFlagIcon,
    KyFlagIcon,
    KzFlagIcon,
    LaFlagIcon,
    LbFlagIcon,
    LcFlagIcon,
    LiFlagIcon,
    LkFlagIcon,
    LrFlagIcon,
    LsFlagIcon,
    LtFlagIcon,
    LuFlagIcon,
    LvFlagIcon,
    LyFlagIcon,
    MaFlagIcon,
    McFlagIcon,
    MdFlagIcon,
    MeFlagIcon,
    MfFlagIcon,
    MgFlagIcon,
    MhFlagIcon,
    MkFlagIcon,
    MlFlagIcon,
    MmFlagIcon,
    MnFlagIcon,
    MpFlagIcon,
    MqFlagIcon,
    MrFlagIcon,
    MsFlagIcon,
    MtFlagIcon,
    MuFlagIcon,
    MvFlagIcon,
    MwFlagIcon,
    MxFlagIcon,
    MyFlagIcon,
    MzFlagIcon,
    NaFlagIcon,
    NcFlagIcon,
    NeFlagIcon,
    NfFlagIcon,
    NgFlagIcon,
    NiFlagIcon,
    NlFlagIcon,
    NoFlagIcon,
    NpFlagIcon,
    NrFlagIcon,
    NuFlagIcon,
    NzFlagIcon,
    OmFlagIcon,
    PaFlagIcon,
    PeFlagIcon,
    PfFlagIcon,
    PgFlagIcon,
    PhFlagIcon,
    PkFlagIcon,
    PlFlagIcon,
    PmFlagIcon,
    PnFlagIcon,
    PrFlagIcon,
    PsFlagIcon,
    PtFlagIcon,
    PwFlagIcon,
    PyFlagIcon,
    QaFlagIcon,
    ReFlagIcon,
    RoFlagIcon,
    RsFlagIcon,
    RuFlagIcon,
    RwFlagIcon,
    SaFlagIcon,
    SbFlagIcon,
    ScFlagIcon,
    SeFlagIcon,
    SdFlagIcon,
    SgFlagIcon,
    ShFlagIcon,
    SiFlagIcon,
    SjFlagIcon,
    SkFlagIcon,
    SlFlagIcon,
    SmFlagIcon,
    SnFlagIcon,
    SoFlagIcon,
    SrFlagIcon,
    SsFlagIcon,
    StFlagIcon,
    SvFlagIcon,
    SxFlagIcon,
    SyFlagIcon,
    SzFlagIcon,
    TaFlagIcon,
    TcFlagIcon,
    TdFlagIcon,
    TfFlagIcon,
    TgFlagIcon,
    ThFlagIcon,
    TjFlagIcon,
    TkFlagIcon,
    TlFlagIcon,
    TmFlagIcon,
    TnFlagIcon,
    ToFlagIcon,
    TrFlagIcon,
    TtFlagIcon,
    TvFlagIcon,
    TwFlagIcon,
    TzFlagIcon,
    UaFlagIcon,
    UgFlagIcon,
    UmFlagIcon,
    UnFlagIcon,
    UsFlagIcon,
    UyFlagIcon,
    UzFlagIcon,
    VaFlagIcon,
    VcFlagIcon,
    VeFlagIcon,
    VgFlagIcon,
    ViFlagIcon,
    VnFlagIcon,
    VuFlagIcon,
    WfFlagIcon,
    WsFlagIcon,
    XkFlagIcon,
    XxFlagIcon,
    YeFlagIcon,
    YtFlagIcon,
    ZaFlagIcon,
    ZmFlagIcon,
    ZwFlagIcon,
  },
}
export default iconComponents
