import { imagesUrl } from '@/assets/images/imageUrls'
import Modal, { RefType } from '@/common/Modal'
import Typography from '@/common/Typography'
import classNames from 'classnames'
import React from 'react'
import { useNavigate } from 'react-router'
import SinglePost from './SinglePost/SinglePost'
import { Pages } from '@/routes/route.constant'

interface DetailObj {
  name: string
  id: number
  desc: string
  img: string
  connections: number
  navigateTo: string
}
interface UsersObj {
  first_name: string
  last_name: string
  id: number
  avatar: string
  navigateTo?: 'user' | 'startups'
}
interface StartupObj {
  name: string
  id: number
  industry: string
  logo: string
  navigateTo?: string
}

interface Props {
  // data: Omit<DetailObj, 'navigateTo'>[]
  isOpen: boolean
  containerClass: string
  toggleModal: (id: number) => void
  investors: {
    count: number
    users: UsersObj[]
  }
  startups: {
    count: number
    startups: StartupObj[]
  }
  posts: {
    count: number
    posts: { id: number; content: string }[]
  }
}

const Details = ({
  name,
  industry,
  logo,
  avatar,
  first_name,
  last_name,
  id,
  navigateTo,
}: Partial<UsersObj & StartupObj>) => {
  const navigate = useNavigate()
  const handleNavigate = () => {
    if (navigateTo === 'startups')
      return navigate(`/app/networks/${navigateTo}/${name}`, {
        state: {
          id,
          from: '/app/networks',
          fromName: 'Network',
        },
      })
    navigate(`/app/networks/${Pages.User}/${`${first_name} ${last_name}`}`, {
      state: {
        id,
        from: '/app/networks',
        fromName: 'Network',
      },
    })
  }
  return (
    <div className='flex gap-2 justify-start border-b-2 border-gray-100 pb-2 flex-row'>
      <img
        src={
          logo ||
          avatar ||
          (navigateTo === 'user'
            ? imagesUrl.blanckProfileImg
            : imagesUrl.blanckCompImg)
        }
        alt='start_up_logo'
        className='h-[70px] w-[70px] object-center object-cover rounded-full'
      />
      <div className='flex flex-col gap-1 px-2 sm:px-3'>
        <div className='flex flex-col items:start justify-center'>
          <Typography
            heading='3xs'
            className='cursor-pointer'
            onClick={handleNavigate}
          >
            {name || `${first_name} ${last_name}`}
          </Typography>
          {navigateTo === 'user' ? (
            <small className='font-light'>{industry}</small>
          ) : (
            <small className='w-fit font-medium bg-blue-400 p-1 rounded-sm'>
              {industry}
            </small>
          )}

          {/* <span className='flex gap-1'>
            <small>
              {' '}
              <strong>{45}</strong>
            </small>
            <small>Connections</small>
          </span> */}
        </div>
      </div>
    </div>
  )
}

function SearchResults({
  isOpen,

  containerClass,
  investors,
  startups,
  posts,
  toggleModal,
}: Partial<Props>) {
  const navigate = useNavigate()

  return (
    <>
      {isOpen && (
        <div
          className={classNames(
            'absolute top-[100%] right-[3px] flex flex-col gap-3  bg-[white] rounded-md border-gray-300 shadow p-[24px] z-20 w-[100%] sm:w-[493px] max-h-[80vh] overflow-y-auto',
            containerClass
          )}
        >
          <>
            <div className='w-full flex justify-between '>
              <span className='flex gap-1'>
                <strong>People</strong>
                <p className='font-light'>{`(${investors?.count || 0})`}</p>
              </span>
            </div>
            {investors?.users.length ? (
              investors?.users.map((item) => (
                <Details key={item.id} {...item} navigateTo='user' />
              ))
            ) : (
              <Typography label='xs'>No data found for this search</Typography>
            )}
          </>
          <>
            <div className='w-full flex justify-between '>
              <span className='flex gap-1'>
                <strong>Businesses</strong>
                <p className='font-light'>{`(${startups?.count || 0})`}</p>
              </span>
            </div>
            {startups?.startups.length ? (
              startups?.startups.map((item) => (
                <Details key={item.id} {...item} navigateTo='startups' />
              ))
            ) : (
              <Typography label='xs'>No data found for this search</Typography>
            )}
          </>
          <>
            <div className='w-full flex justify-between '>
              <span className='flex gap-1'>
                <strong>Posts</strong>
                <p className='font-light'>{`(${posts?.count || 0})`}</p>
              </span>
            </div>
            {posts?.posts.length ? (
              posts?.posts.map((post) => (
                <small
                  key={post.id}
                  dangerouslySetInnerHTML={{ __html: post?.content }}
                  onClick={() => toggleModal && toggleModal(post.id)}
                  className='cursor-pointer border-b-2 border-gray-100 pb-2 min-h-[20px] w-[100%]'
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                />
              ))
            ) : (
              <Typography label='xs'>No data found for this search</Typography>
            )}
          </>
        </div>
      )}
    </>
  )
}

export default SearchResults
