import {
  useState,
  useRef,
  useEffect,
  useCallback,
  MutableRefObject,
} from 'react'

function useHover<T>(): [MutableRefObject<T>, boolean] {
  const [value, setValue] = useState(false)
  const ref: any = useRef<T | null>(null)
  const handleMouseOver = useCallback(() => setValue(true), [])
  const handleMouseOut = useCallback(() => setValue(false), [])
  useEffect(
    () => {
      const node: any = ref.current
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)
        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current, handleMouseOut, handleMouseOver]
  )
  return [ref, value]
}

export default useHover
