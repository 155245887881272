import React, { useRef } from 'react'

interface Props {
  onFileSelected: (file: File | File[], imgUrl?: string) => void
  btnElement: React.ReactElement
  type?: 'image' | 'video'
  multiple?: boolean
}

function FileUpload({
  onFileSelected,
  btnElement,
  type = 'image',
  multiple = false,
}: Props) {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files) {
      if (multiple) {
        const fileArray = Array.from(files)
        onFileSelected(fileArray)
      } else {
        const file = files[0]
        const fileUrl = URL.createObjectURL(file)
        onFileSelected(file, fileUrl)
      }
    }
  }

  return (
    <>
      {React.cloneElement(btnElement, {
        onClick: handleButtonClick,
      })}

      <input
        type='file'
        accept={type === 'image' ? 'image/*' : 'video/*'}
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        multiple={multiple}
      />
    </>
  )
}

export default FileUpload
