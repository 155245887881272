import Typography from '@/common/Typography'
import Avatar from '@/common/avatar/Avatar'
import CircularProgress from '@/common/spinners/CircularProgress'
import { useAuthContext } from '@/contexts/AuthContextProvider'
import { Pages } from '@/routes/route.constant'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'

const Setup = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { getAuthState, isOrganisational } = useAuthContext()
  const userState = getAuthState()

  useEffect(() => {
    let timeId: ReturnType<typeof setTimeout>
    // Todo: Do all prefetch work of network timeline and profile info

    timeId = setTimeout(() => {
      if (isOrganisational()) {
        navigate(`/app/${Pages.Fund}`)
      } else {
        navigate(`/app/${Pages.Dashboard}`)
      }
    }, 3000)

    return () => {
      clearTimeout(timeId)
    }
  }, [navigate, isOrganisational])
  const from = (state ?? { from: 'sign-in' }).from

  return (
    <article className='w-full h-full flex flex-col flex-1 justify-between items-center gap-12'>
      <header>
        <Typography
          as='h1'
          className='text-[36px] leading-[44px] font-normal text-sifuse-shades-900'
        >
          Welcome in!
        </Typography>
      </header>
      <main className='flex flex-col justify-center items-center gap-2 self-stretch pb-[120px]'>
        <Avatar size='xl' src={userState?.avatar || '/defaultImage.svg'} />
        <hgroup className='flex flex-col items-center justify-center'>
          <Typography
            as='h2'
            className='text-sifuse-shades-900 text-[28px] font-medium leading-[44px]'
          >
            {userState?.first_name} {userState?.last_name}
          </Typography>
          <Typography
            as='p'
            paragraph='md'
            className='text-center text-sifuse-shades-600'
          >
            {userState?.email}
          </Typography>
        </hgroup>
      </main>
      <footer className='flex-1 flex  lg:mb-[112px] flex-col justify-start items-center text-center gap-4'>
        <Typography
          as='h4'
          className='text-sifuse-shades-600 text-center text-[20px] font-medium'
        >
          {from === 'sign-in'
            ? 'Just a moment...'
            : 'Just finishing your set-up...'}
        </Typography>
        <div className='max-w-[25px] flex justify-center items-center mx-auto'>
          <CircularProgress size='20px' color='#272343' />
        </div>
      </footer>
    </article>
  )
}

export default Setup
