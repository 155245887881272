export const generateThumbnail = async (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    const canvas = document.createElement('canvas')

    video.width = 320
    video.height = 240
    video.src = URL.createObjectURL(file)

    // Set the video time to a point in the video, e.g., 2 seconds in
    video.currentTime = 3

    video.onseeked = () => {
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      const context = canvas.getContext('2d')

      if (context) {
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

        // Use canvas.toBlob to convert to a Blob, then create the File
        canvas.toBlob((blob) => {
          if (blob) {
            const thumbnailFile = new File(
              [blob],
              `${file.name}_thumbnail.jpeg`,
              {
                type: 'image/jpeg',
                lastModified: Date.now(),
              }
            )
            resolve(thumbnailFile)
          } else {
            reject(new Error('Failed to create thumbnail blob'))
          }
          URL.revokeObjectURL(video.src)
        }, 'image/jpeg')
      } else {
        reject(new Error('Failed to get canvas context'))
      }
    }

    video.onerror = () => {
      reject(new Error('Failed to load video'))
      URL.revokeObjectURL(video.src)
    }

    // Wait for the video to be ready to seek
    video.onloadeddata = () => {
      video.currentTime = 2
    }
  })
}

export const handleFilesWithVideo = async (
  filesToUpload: File[]
): Promise<File[]> => {
  const videoFiles = filesToUpload.filter((file) =>
    file.type.startsWith('video/')
  )

  let fileArray = Array.from(videoFiles) as File[]
  fileArray = await Promise.all(
    fileArray.map((file) => generateThumbnail(file))
  )
  return [...filesToUpload, ...fileArray]
}

export const formatStoreResponseForPost = (
  storeRes: any[],
  postId: number,
  currentUrl: string,
  contentName?: string
) => {
  // Regular expression for matching image files
  const imgRegex = /\.(jpg|jpeg|png|gif|bmp|webp|tiff|svg)$/i

  const thumbnail =
    storeRes
      .map((_f: any) => _f.url)
      .filter((fileUrl: string) => fileUrl.includes('_thumbnail'))
      .find((_thumbnail) => {
        const originalName = _thumbnail.split('_thumbnail')[0]
        return currentUrl === originalName
      }) || ''

  const fileData: Record<string, any> = {}

  fileData['file'] = currentUrl
  fileData[contentName || 'post'] = postId
  fileData['metadata'] = {
    type: imgRegex.test(currentUrl) ? 'image' : 'video',
    thumbnail,
  }
  return fileData
}
