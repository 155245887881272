import { Dispatch, SetStateAction, useMemo, useState } from 'react'

import { contextFactory } from './helpers/contextFactory'
import { useToggleState } from '@/hooks/useToggleState'

type ModalActions = {
  showModal: () => void
  hideModal: () => void
  toggleModal: () => void
  isModalVisible: boolean
}
interface ModalDetailsType {
  type: string
  title?: string
  content?: Record<any, any>
  subtitle?: string
}

const [useModalActionsContext, ModalActionsContext] = contextFactory<
  ModalActions & {
    modalDetails: ModalDetailsType | null
    setmodalDetails: Dispatch<SetStateAction<ModalDetailsType | null>>
  }
>()
export { useModalActionsContext }

interface Props {
  children: React.ReactNode
}

const ModalContextProvider = (props: Props) => {
  const { children } = props
  const [modalDetails, setmodalDetails] = useState<ModalDetailsType | null>(
    null
  )
  const {
    state: isModalVisible,
    open: showModal,
    close: hideModal,
    toggle: toggleModal,
  } = useToggleState(false)

  const actions = useMemo(
    () => ({
      isModalVisible,
      showModal,
      hideModal,
      toggleModal,
      modalDetails,
      setmodalDetails,
    }),
    [modalDetails, isModalVisible]
  )

  return (
    <ModalActionsContext.Provider value={actions}>
      {' '}
      {children}
    </ModalActionsContext.Provider>
  )
}

export default ModalContextProvider
