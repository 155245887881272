import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import { generateId } from '../helpers'

const useMountPortal = (id: string = 'toast-portal ') => {
  const [isLoading, setIsLoading] = useState(true)
  const toastsId = useRef(id + generateId())
  useEffect(() => {
    setIsLoading(true)

    const div = document.createElement('div')
    div.id = toastsId.current
    document.body.prepend(div)
    setIsLoading(false)

    return () => {
      document.body.removeChild(div)
    }
  }, [])

  return { isLoading, portalId: toastsId.current }
}

export default useMountPortal
