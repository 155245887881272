import clsx from 'clsx'
import Image from '../Image'

type Props = {
  src?: string
  type?: 'rounded' | 'square'
  notification?: boolean
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
  className?: string
  notificationClassName?: string
  name?: string
  useName?: boolean
  style?: Partial<CSSStyleDeclaration>
}

const StyleMaps = {
  size: {
    xl: 'w-[144px] h-[144px] max-w-[144px]',
    lg: 'w-[80px] h-[80px] max-w-[80px]',
    md: 'w-[48px] h-[48px] max-w-[48px]',
    sm: 'w-[32px] h-[32px] max-w-[32px]',
    xs: 'w-[24px] h-[24px] max-w-[24px]',
  },
  type: {
    rounded: 'rounded-full',
    square: 'rounded',
  },
  notification: {
    xl: 'w-[28px] h-[28px] border-[3px] border-sifuse-shades-50 -top-2 -right-[14px]',
    lg: 'w-[20px] h-[20px] border-[2px] border-sifuse-shades-50 -top-[5px] -right-[10px]',
    md: 'w-[16px] h-[16px] border-[2px] border-sifuse-shades-50 -top-1 -right-[8px]',
    sm: 'w-[12px] h-[12px] border-[1.5px] border-sifuse-shades-50 -top-[3px] -right-[6px]',
    xs: 'w-[10px] h-[10px] border-[1px] border-sifuse-shades-50 -top-0.5 -right-[5px]',
  },
}
const Avatar = (props: Props) => {
  const {
    src = '/defaultImage.svg',
    size = 'xl',
    type = 'rounded',
    notification,
    className,
    notificationClassName,
    name,
    useName = false,
    style,
  } = props

  return (
    <div className='relative'>
      {useName ? (
        <span
          style={style as any}
          className={clsx(
            ' flex justify-center items-center min-h-fit font-bold uppercase bg-[rgba(0,_0,_0,_0.20)]',
            StyleMaps.size[size],
            StyleMaps.type[type],
            className
          )}
        >
          {name}
        </span>
      ) : (
        <Image
          style={style as any}
          src={src || '/defaultImage.svg'}
          alt='representation of user'
          className={clsx(
            ' object-center object-cover',
            StyleMaps.size[size],
            StyleMaps.type[type],
            className
          )}
        />
      )}

      {notification && (
        <span
          className={clsx(
            'absolute rounded-full bg-[#31C48D]',
            StyleMaps.notification[size],
            notificationClassName
          )}
        ></span>
      )}
    </div>
  )
}

export default Avatar
