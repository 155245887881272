type ReturnData = {
  src: string
  userId: number
  name: string
  title: string
  isGroup: boolean
}

export const formatUserDetails = (data: Record<string, any>): ReturnData => {
  const returnData: ReturnData = {
    name: '',
    src: '',
    title: '',
    userId: 0,
    isGroup: false,
  }

  returnData.name =
    data?.user?.user_type === 'Startup'
      ? data?.user?.startup?.name
      : data?.user?.name || `${data?.user?.first_name} ${data?.user?.last_name}`
  returnData.src =
    data?.user?.user_type === 'Startup'
      ? data?.user?.startup?.logo
      : data?.user?.avatar
  returnData.title = data?.user?.user_type

  returnData.userId =
    data?.user?.user_type === 'Startup'
      ? data?.user?.startup?.id
      : data?.user?.id

  returnData.isGroup = data?.user?.user_type === 'Startup'

  return returnData
}
