import React, { useRef, useState } from 'react'
import ButtonTab from './components/ButtonTab'
import TextInput from '@/common/TextInput'

import Button from '@/common/button/Button'
import { ReactComponent as SearchIcon } from '@/assets/network/search-normal.svg'
import Post from './components/Post'
import ListView from './components/ListView'
import { useLocation, useNavigate } from 'react-router'
import SearchResults from './components/SearchResults'
import { FaUpRightAndDownLeftFromCenter, FaX } from 'react-icons/fa6'

import ModalContainer from './components/postModal/ModalContainer'

import { POSTS } from '@/constants/querryKeys'

import useIntersectionObserver from '@/hooks/useIntersectionObserver'
import LoadingState from './components/LoadingState'
import Typography from '@/common/Typography'
import { InfiniteScroll, Ref } from './components/utils/types'
import SinglePost from './components/SinglePost/SinglePost'
import { useBreadCrumbs } from '@/layouts/app/components/header'
import PostSkeletonLoader from './components/PostSkeletonLoader'
import Title from '@/common/Title'
import {
  useGetPosts,
  useGetRecommendedInvestors,
  useGetTrendingStartups,
  useSearchTimeline,
} from '@/store/networkStore'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { WEB_SOCKET_POST } from '@/apis/endpoints'
import { useAuthContext } from '@/contexts/AuthContextProvider'
import { useQueryClient } from 'react-query'
import NewPostNotification from './components/NewPostNotification'

import useQueryParams from '@/hooks/useQueryParams'
import Modal, { RefType } from '@/common/Modal'
import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'
import { checkCategory } from './components/utils/checkCategory'
import { TimelineResultsStructure } from '@/apis/networkApis'
import DropdownButton from '@/common/DropdownButton'

//https://loremflickr.com/320/240

interface TimelineSearchData {
  startups: {
    count: number
    startups: any[]
  }
  users: { count: number; users: any[] }
  posts: { count: number; posts: any[] }
}
const BreadCrumbMap = new Map()

BreadCrumbMap.set('/app/networks', 'The Timeline')

function Timeline() {
  useBreadCrumbs(BreadCrumbMap)
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const modalRef = useRef<RefType>(null)
  const modalRef1 = useRef<RefType>(null)
  const modalRef2 = useRef<RefType>(null)
  const modalRef3 = useRef<RefType>(null)
  const parentRef = useRef<HTMLDivElement | null>(null)

  const postTopRef = useRef<HTMLDivElement | null>(null)
  const isInTop = useRef<boolean>(false)
  const [timelineSearch, settimelineSearch] = React.useState<string>('')
  const [showScrollToTop, setshowScrollToTop] = React.useState<boolean>(false)

  const { getAccessToken } = useAuthContext()
  const params = useQueryParams()
  const token = getAccessToken()
  const [postId, setpostId] = React.useState<number | null>()

  isInTop.current = useIntersectionObserver(postTopRef as Ref)

  const dropDownOptions = [
    {
      name: 'Recommended Investor Connections',
      value: 'recommended_investors',
    },
    { name: 'Trending Businesses to Connect', value: 'trending_business' },
  ]
  //check if link is copied
  React.useEffect(() => {
    if (params?.post) {
      setpostId(params?.post)
      modalRef1?.current?.handleToggle()
    }
  }, [params?.post])

  React.useEffect(() => {
    const client = new W3CWebSocket(WEB_SOCKET_POST + token + '/')

    client.onopen = () => {
      console.log('WebSocket Client Connected TO posts')
    }
    client.onmessage = (ev: any) => {
      let dataFromServer = {}

      try {
        dataFromServer = JSON.parse(ev.data)
        if (Object.keys(dataFromServer).length) {
          // const { action } = dataFromServer
          queryClient.invalidateQueries([POSTS])
          if (isInTop.current === false) {
            setshowScrollToTop(true)
          }
        }
      } catch (err) {
        console.log(ev.data, 'DATA FROM SERVER')
      }
    }
  }, [token, isInTop.current])

  const handleTabclick = (path: string) => {
    navigate(path)
  }

  const toggleModal1 = (id: number): void => {
    setpostId(id)
    modalRef1?.current?.handleToggle()
  }

  const toggleModal = (): void => {
    modalRef?.current?.handleToggle()
  }
  const toggleModal2 = (): void => {
    modalRef2?.current?.handleToggle()
  }
  const toggleModal3 = (): void => {
    modalRef3?.current?.handleToggle()
  }

  //recomended investors
  const { data: recomendedInvestors, isLoading: recomendedInvestorsLoading } =
    useGetRecommendedInvestors()

  //trending startups to connect
  const { data: startUpsToConnect, isLoading: startUpsToConnectLoading } =
    useGetTrendingStartups()

  //search timeline
  const { data: timelineSearchData, isLoading: timelineSearchDataLoading } =
    useSearchTimeline({ search: timelineSearch })
  const timelineSearchDataMain =
    timelineSearchData as unknown as TimelineSearchData

  //get posts
  const {
    data: apiPosts,
    isLoading: postsLoading,
    isError: isPostError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetPosts()

  const posts = (apiPosts as unknown as InfiniteScroll)?.pages
  const isPostEmpty = posts?.[0].data?.data?.results?.length < 1

  const scrollToTop = () => {
    parentRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
    setshowScrollToTop(false)
  }

  const handleClickFilter = (clickedItem: string) => {
    switch (clickedItem) {
      case 'recommended_investors':
        toggleModal2()
        break
      case 'trending_business':
        toggleModal3()
        break

      default:
        break
    }
  }

  return (
    <div ref={parentRef}>
      <Title>The Timeline</Title>
      <NewPostNotification
        onClick={scrollToTop}
        show={showScrollToTop}
        setshow={setshowScrollToTop}
      />
      <div className='flex flex-col justify-between items-start sm:flex-row sm:items-center width-full'>
        <ButtonTab
          activeProperty={location.pathname}
          onClick={handleTabclick}
        />
        <div className='relative w-[80vw] sm:w-auto'>
          <TextInput
            placeholder='Search on the timeline'
            name='timelineSearch'
            value={timelineSearch}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              settimelineSearch(e.target.value)
            }
            startIcon={<SearchIcon />}
            endIcon={
              !!timelineSearch && (
                <FaX
                  className='text-[0.7rem]'
                  onClick={() => settimelineSearch('')}
                />
              )
            }
            className='w-full border border-gray-200 md:w-[200px] sm:h-[48px] lg:w-[364px]'
          />
          <SearchResults
            investors={timelineSearchDataMain?.users}
            startups={timelineSearchDataMain?.startups}
            posts={timelineSearchDataMain?.posts}
            isOpen={!!timelineSearch}
            toggleModal={toggleModal1}
          />
        </div>
      </div>

      <div className='flex flex-col justify-start gap-2  mt-3 sm:flex-row width-full'>
        {/* timeline */}

        <div className='flex-col flex gap-4 w-full md:w-[65%] lg:w-[70%]'>
          <div className='bg-[white] flex flex-col rounded-md gap-2 p-[24px] w-full border border-[#E6E6EB]'>
            <span className='flex w-full items-center justify-between'>
              <Typography heading='sm'> Timeline</Typography>
              <span className='md:hidden'>
                <DropdownButton
                  changeTitleOnSelect={false}
                  items={dropDownOptions}
                  trayClass='!min-w-[240px] !left-[-180px]'
                  onClick={(item: any) => handleClickFilter(item)}
                  ClickComponent={
                    <Button color='plain'>
                      <svg
                        width='22'
                        height='5'
                        viewBox='0 0 22 5'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.75 3.43298C3.37132 3.43298 3.875 2.9293 3.875 2.30798C3.875 1.68666 3.37132 1.18298 2.75 1.18298C2.12868 1.18298 1.625 1.68666 1.625 2.30798C1.625 2.9293 2.12868 3.43298 2.75 3.43298Z'
                          stroke='black'
                          strokeWidth='2.25'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M11 3.43298C11.6213 3.43298 12.125 2.9293 12.125 2.30798C12.125 1.68666 11.6213 1.18298 11 1.18298C10.3787 1.18298 9.875 1.68666 9.875 2.30798C9.875 2.9293 10.3787 3.43298 11 3.43298Z'
                          stroke='black'
                          strokeWidth='2.25'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M19.25 3.43298C19.8713 3.43298 20.375 2.9293 20.375 2.30798C20.375 1.68666 19.8713 1.18298 19.25 1.18298C18.6287 1.18298 18.125 1.68666 18.125 2.30798C18.125 2.9293 18.6287 3.43298 19.25 3.43298Z'
                          stroke='black'
                          strokeWidth='2.25'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </Button>
                  }
                />
              </span>
            </span>

            <Button
              onClick={toggleModal}
              endIcon={<FaUpRightAndDownLeftFromCenter />}
              startIcon={<small> Start typing...</small>}
              color='lightGrey'
              className='w-full items-start justify-start flex text-gray-400 mb-2 border border-gray-200 hover:shadow-sm'
            ></Button>
          </div>
          <div className='h-fit-content min-h-[60vh] flex-col flex gap-2'>
            <div ref={postTopRef} />
            {
              //if first load

              <LoadingState
                skeletonLoader={<PostSkeletonLoader />}
                condition={
                  postsLoading
                    ? 'isLoading'
                    : isPostEmpty
                    ? 'isEmpty'
                    : isPostError
                    ? 'isError'
                    : ''
                }
                isEmptyDisplay={
                  <>
                    <Typography heading='3xs'>No post on timeline</Typography>
                    <Typography label='xs'>
                      You have not applied for a job yet
                    </Typography>
                  </>
                }
              />
            }
            <InfiniteScrollContainer
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
            >
              <div className='flex-col flex gap-2'>
                {posts?.map((page) =>
                  page?.data.data?.results?.map(
                    (post: TimelineResultsStructure) => (
                      <Post key={post.id} {...post} />
                    )
                  )
                )}
              </div>
            </InfiniteScrollContainer>
          </div>
        </div>

        {/* Recommended Investor Connections */}
        <div className='hidden flex-col gap-4 w-full md:flex md:w-[35%]  lg:w-[30%]'>
          <ListView
            title='Recommended Investor Connections'
            list={
              Array.isArray(recomendedInvestors) && recomendedInvestors.length
                ? recomendedInvestors
                : []
            }
            onClickItem={(item) =>
              navigate(`/app/networks/user/${item?.name}`, {
                state: {
                  id: item?.id,
                  from: '/app/networks',
                  fromName: 'Network',
                },
              })
            }
          />
          {/* Trending Startups to Connect */}
          <ListView
            title='Trending Businesses to Connect'
            list={
              Array.isArray(startUpsToConnect) && startUpsToConnect.length
                ? startUpsToConnect
                : []
            }
            onClickItem={(item) =>
              navigate(`/app/networks/startups/${item?.startup?.name}`, {
                state: {
                  id: item?.startup?.id,
                  from: '/app/networks',
                  fromName: 'Network',
                },
              })
            }
          />
        </div>
      </div>
      <Modal
        ref={modalRef1}
        modalClass='w-[90vw] sm:w-[800px] min-h-[80vh] pt-2'
      >
        <SinglePost id={postId} />
      </Modal>
      <Modal
        ref={modalRef}
        modalClass='w-[90vw] sm:w-[800px] min-h-[50vh]'
        showCloseBtn
        enableWarning
      >
        <ModalContainer toggleModal={toggleModal} />
      </Modal>
      <Modal
        ref={modalRef2}
        modalClass='w-[90vw] sm:w-[800px] min-h-[50vh] !p-0'
        showCloseBtn
      >
        <ListView
          isModal
          title='Recommended Investor Connections'
          list={
            Array.isArray(recomendedInvestors) && recomendedInvestors.length
              ? recomendedInvestors
              : []
          }
          onClickItem={(item) =>
            navigate(`/app/networks/user/${item?.name}`, {
              state: {
                id: item?.id,
                from: '/app/networks',
                fromName: 'Network',
              },
            })
          }
        />
      </Modal>
      <Modal
        ref={modalRef3}
        modalClass='w-[90vw] sm:w-[800px] min-h-[50vh] !p-0'
        showCloseBtn
      >
        <ListView
          isModal
          title='Trending Businesses to Connect'
          list={
            Array.isArray(startUpsToConnect) && startUpsToConnect.length
              ? startUpsToConnect
              : []
          }
          onClickItem={(item) =>
            navigate(`/app/networks/startups/${item?.startup?.name}`, {
              state: {
                id: item?.startup?.id,
                from: '/app/networks',
                fromName: 'Network',
              },
            })
          }
        />
      </Modal>
    </div>
  )
}

export default Timeline
