export const PermissionNames = {
  'Can View Dashboards': 1,
  'Can View Businesses': 2,
  'Can Manage & Update Businesses': 3,
  'Can View User Roles and Permissions': 4,
  'Can Manage and Invite Users': 5,
  'Can Manage User Roles': 6,
  'Can View Network': 7,
  'Can Manage Network': 8,
  'Can View Community': 9,
  'Can Manage Community': 10,
  'Can View Portfolio': 11,
  'Can Edit Portfolio': 12,
  'Can View Fund': 13,
  'Can Manage Fund': 14,
  'Can View Message': 15,
  'Can View Deals': 19,
  'Can Manage Deals': 20,
}
