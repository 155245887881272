import { InfiniteScroll } from '@/pages/theNetwork/components/utils/types'
import api from './api'
import { PaginatedData, ServerRes } from './api.types'
import {
  CONNECTIONS,
  POSTS,
  GET_STARTUPS,
  GET_STARTUPS_HIGHLIGHTS,
  GET_USERS,
  MENTIONS,
  POST_COMMENT,
  POST_MEDIA,
  RECOMMENDED_INVESTORS,
  SEARCH_TIMELINE,
  TRENDING_STARTUPS,
  GET_TIMELINE_ACTIVITIES,
  JOIN_USER_GROUPS,
  GET_INVESTORS_GROUP,
  GET_GROUPS,
} from './endpoints'
import { sanitizeQueryParams } from '@/helpers/sanitizeQueryParams'

export interface StartUpStructure {
  id: number
  country: string
  members: {
    id: number

    connection: null | {
      id: number
      status: 'Pending' | 'Accepted' | 'Declined'
      is_sender: boolean
    }
    email: string
    first_name: string
    last_name: string
    phone_number: string
    user_type: 'Startup' | 'Investor' | 'Admin'
    email_verified: boolean
    date_joined: Date
    last_login: Date
    about: string
    avatar: string
  }[]
  funding: any[]

  name: string
  logo: string
  industry:
    | 'Technology'
    | 'Education'
    | 'Health Services'
    | 'Financial Services'
    | 'Forestry'
    | 'Mining'
  product_type: string
  is_registered: boolean
  about_us: string
  website: string
  mission: string
  vision: string
  bio_video: string
  created: Date
  updated: Date
  connection: null | {
    id: number
    status: 'Pending' | 'Accepted' | 'Declined'
    is_sender: boolean
  }
}
export interface TimelineResultsStructure {
  content: string
  created: Date
  id: number
  updated: string
  media: any[]
  mentioned_startups: []
  mentioned_users: []
  num_comments: number
  num_likes: number
  like: boolean
  user: {
    avatar: string
    name: string
    about: string
    id: number
    user_type: string
    startup: { id: number; name: string; logo: string } | null
    connection?: null | {
      id: number
      status: 'Pending' | 'Accepted' | 'Declined'
      is_sender: boolean
    }
  }
  investor_group: InvestorGroupType
}

export interface InvestorStructure {
  id: number
  email: string
  address: string
  first_name: string
  last_name: string
  phone_number: string
  user_type: string
  avatar: string
  about: string
  last_login: Date
  date_joined: Date
  email_verified: boolean
  num_connections: number
  num_followers: number
  connection: null | {
    id: number
    status: 'Pending' | 'Accepted' | 'Declined'
    is_sender: boolean
  }
  follower: boolean
  following: boolean
  startups: { id: number; name: string; logo: string; isFounder: boolean }[]
}

export type PostConnection = {
  id: number
  user: User
  content: string
  mentioned_users: User[]
  mentioned_startups: Mentionedstartup[]
  like: boolean
  num_likes: number
  num_comments: number
  media: Media[]
  created: string
  updated: string
}

interface Media {
  id: number
  post: number
  file: string
  file_type: string
  video_cover: string
}

interface Mentionedstartup {
  id: number
  name: string
  logo: string
}

interface User {
  id: number
  name: string
  about: string
  avatar: string
  num_connections: number
  connection: string
}

type Connection = {}
export type ConnectionReqDTO = {
  status: 'Accepted' | 'Declined'
  id: number
  type?: 'user' | 'group'
  message?: string
}
export type GetPostsReqDTO = {
  page: number
  startup_id?: number
  period?: number
}
export type GetConnectionsReqDTO = {
  page: number
  status?: 'Pending' | 'Accepted'
}
export type GetStartupsReqDTO = {
  page: number
  query: string
}
export type GetCommentsReqDTO = {
  page: number
  postId: number
}
export type GetTimelineActivitiesReqDTO = {
  page: number
  activity_type: 'all' | 'comment' | 'like' | 'post'
  user_id: number
  query?: string
  period?: number
}
export type PostCommentReqDTO = {
  content: string
  id: number
}

export interface PostMediaReqDTO {
  post: number
  file: string
}
export type CreatePostReqDTO = {
  content: string
  mentioned_users: number[]
  mentioned_startups: number[]
}
export type GetUsersReqDTO = GetStartupsReqDTO & {
  'user type': 'Investor' | 'Startup' | ''
}
export interface GetInvestorGroupsResDTO {
  id: number
  name: string
  img: string
  investor_type: string
  num_connections: number
  connection: null | {
    id: number
    status: 'Pending' | 'Accepted' | 'Declined'
    is_sender: boolean
  }
  member: Member | null
}
export interface Member {
  id: number
  user_id: string
  first_name: string
  last_name: string
  email: string
  phone_number: string
  date_joined: string
  group: number
  role: string
  status: string
  is_accepted: boolean
  connection: null | {
    id: number
    status: 'Pending' | 'Accepted' | 'Declined'
    is_sender: boolean
  }
  avatar: string
  about: string
}
export interface GroupDetailsResDTO {
  id: number
  members: Member[]
  category: string
  name: string
  logo: string
  about: string
  structure: string
  registration_id: string
  address: string
  email: string
  phone_number: string
  website: string
  created: string
  updated: string
  num_connections: number
  connection: null | {
    id: number
    status: 'Pending' | 'Accepted' | 'Declined'
    is_sender: boolean
  }
  member: Member | null
}
export type GetTimelineSearchReqDTO = {
  search: string
}
export type JoinGroupReqDTO = {
  groupId: number
}

export type GetInvestorsGroupReqDTO = GetStartupsReqDTO & {
  category: 'Group' | 'Individual' | 'Network' | 'Syndicate' | 'Fund'
}
export type InvestorGroupType = {
  id: number
  name: string
  logo: string
  category: string
} | null
//Join Syndicate
export const JoinUserGroup = async (data: JoinGroupReqDTO) => {
  return await api.post<ServerRes<Connection>>(JOIN_USER_GROUPS, {
    group: data.groupId,
  })
}
export const DeleteUserMembership = async (data: { id: number }) => {
  return await api.delete<ServerRes<Connection>>(JOIN_USER_GROUPS + data.id)
}

//deny connections
export const ChangeConnectionStatus = async (data: ConnectionReqDTO) => {
  return await api.patch<ServerRes<Connection>>(CONNECTIONS + `${data.id}/`, {
    status: data.status,
  })
}

//post comment
export const PostComment = async (data: PostCommentReqDTO) => {
  return await api.post<ServerRes<Connection>>(POST_COMMENT, {
    content: data.content,
    post: data.id,
  })
}

//toggle like
export const ToggleLike = async (data: Pick<ConnectionReqDTO, 'id'>) => {
  return await api.post<ServerRes<Connection>>(
    `${POSTS}${data.id}/toggle-like/`,
    {}
  )
}
//get timeline mentions
export const GetMentions = async (data: Pick<GetStartupsReqDTO, 'query'>) => {
  return await api.get<ServerRes<Connection>>(`${MENTIONS}?query=${data.query}`)
}
//create connections
export const CreateConnection = async (
  data: Pick<ConnectionReqDTO, 'id' | 'type' | 'message'>
) => {
  return await api.post<ServerRes<Connection>>(CONNECTIONS, {
    recipient: data.id,
    type: data?.type || 'user',
    message: data?.message,
  })
}

//create post media
export const CreatePostMedia = async (data: PostMediaReqDTO) => {
  return await api.post<ServerRes<Connection>>(POST_MEDIA, data)
}

//create post
export const CreatePost = async (data: CreatePostReqDTO) => {
  return await api.post<ServerRes<PostConnection>>(POSTS, {
    content: data.content,
    mentioned_users: data.mentioned_users,
    mentioned_startups: data.mentioned_startups,
  })
}

//delete connections
export const DeleteConnection = async (data: Partial<ConnectionReqDTO>) => {
  return await api.delete<ServerRes<Connection>>(CONNECTIONS + `${data.id}/`)
}
//delete post
export const DeletePost = async (data: Partial<ConnectionReqDTO>) => {
  return await api.delete<ServerRes<Connection>>(POSTS + `${data.id}/`)
}
export const SearchTimeline = async (data: GetTimelineSearchReqDTO) => {
  return await api.get<ServerRes<Connection>>(
    SEARCH_TIMELINE + `?query=${data.search}/`
  )
}

//get trending startups to connect
export const GetTrendingStartups = async () => {
  return await api.get<ServerRes<Connection>>(TRENDING_STARTUPS)
}

//get recommended investors
export const GetRecomendedInvestors = async () => {
  return await api.get<ServerRes<Connection>>(RECOMMENDED_INVESTORS)
}

//get comments
export const GetComments = async (data: GetCommentsReqDTO) => {
  return await api.get<ServerRes<InfiniteScroll>>(
    `${POST_COMMENT}?post=${data.postId}&page=${data.page}`
  )
}
//get posts
export const GetPosts = async (data: GetPostsReqDTO) => {
  return await api.get<ServerRes<InfiniteScroll>>(
    sanitizeQueryParams(
      `${POSTS}?page=${data.page}&startup_id=${data.startup_id}&period=${data.period}`
    )
  )
}
//get timeline activities
export const GetTimelineActivities = async (
  data: GetTimelineActivitiesReqDTO
) => {
  return await api.get<ServerRes<InfiniteScroll>>(
    sanitizeQueryParams(
      `${GET_TIMELINE_ACTIVITIES}?page=${data.page}&activity_type=${data.activity_type}&user_id=${data.user_id}&period=${data.period}&query=${data.query}`
    )
  )
}
//get post
export const GetPost = async (data: Pick<ConnectionReqDTO, 'id'>) => {
  return await api.get<ServerRes<Connection>>(`${POSTS}${data.id}/`)
}

//get connections
export const GetConnections = async (data: GetConnectionsReqDTO) => {
  return await api.get<ServerRes<InfiniteScroll>>(
    `${CONNECTIONS}?page=${data.page}&status=${data?.status}`
  )
}

//get startups
export const GetStartups = async (data: GetStartupsReqDTO) => {
  return await api.get<ServerRes<InfiniteScroll>>(
    sanitizeQueryParams(
      `${GET_STARTUPS}?page=${data.page}&query=${data?.query}`
    )
  )
}
//get startup
export const GetStartup = async (data: Pick<ConnectionReqDTO, 'id'>) => {
  return await api.get<ServerRes<Connection>>(`${GET_STARTUPS}${data.id}/`)
}
//get startup highlights
export const GetStartupHighlights = async (
  data: Pick<ConnectionReqDTO, 'id'>
) => {
  return await api.get<ServerRes<Connection>>(
    sanitizeQueryParams(`${GET_STARTUPS_HIGHLIGHTS}?startup=${data.id}`)
  )
}
//get users
export const GetUsers = async (data: GetUsersReqDTO) => {
  return await api.get<ServerRes<InfiniteScroll>>(
    sanitizeQueryParams(
      `${GET_USERS}?page=${data.page}&query=${data?.query}&user type=${data['user type']}`
    )
  )
}

//get user
export const GetUser = async (data: Pick<ConnectionReqDTO, 'id'>) => {
  return await api.get<ServerRes<Connection>>(`${GET_USERS}${data.id}/`)
}

//get investors group
export const GetInvestorsGroup = async (data: GetInvestorsGroupReqDTO) => {
  return (
    await api.get<ServerRes<PaginatedData<GetInvestorGroupsResDTO>>>(
      sanitizeQueryParams(
        `${GET_INVESTORS_GROUP}?page=${data.page}&search=${data?.query}&category=${data?.category}`
      )
    )
  ).data.data
}
//get group details
export const getGroupDetails = async (data: { id: number }) => {
  return (
    await api.get<ServerRes<GroupDetailsResDTO>>(`${GET_GROUPS}${data.id}/`)
  ).data.data
}
