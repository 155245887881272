import React from 'react'
import TeamInfoCard from './TeamInfoCard'
import Button from '@/common/button/Button'
import { FaArrowRight } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router'
import Typography from '@/common/Typography'

interface Props {
  data: {
    id: number
    email: string
    first_name: string
    last_name: string
    phone_number: string
    user_type: 'Startup' | 'Investor' | 'Admin'
    email_verified: boolean
    date_joined: Date
    last_login: Date

    connection: null | {
      id: number
      status: 'Pending' | 'Accepted' | 'Declined'
      is_sender: boolean
    }
    about: string
    avatar: string
  }[]
}

function TeamInfo({ data }: Props) {
  const navigate = useNavigate()
  const { startupName } = useParams()
  const { state } = useLocation()
  const startupId = Number(state?.id)
  return (
    <div className='bg-[white] p-4  rounded-lg flex flex-col gap-2 items-start w-full '>
      <Typography heading='3xs'>Team Info</Typography>

      <small className='font-light'>
        See the staff present within the organization
      </small>
      <div className='grid grid-cols-2  sm:grid-cols-3 lg:grid-cols-4 gap-4 mt-6 sm:gap:8 lg:gap-12'>
        {data?.map((team) => (
          <TeamInfoCard key={`${team.id}`} {...team} />
        ))}
      </div>
      <Button
        className='py-2 px-5 rounded-lg mt-5'
        onClick={() =>
          navigate(`/app/networks/startups/${startupName}/teaminfo`, {
            state: {
              id: startupId,
            },
          })
        }
        endIcon={<FaArrowRight />}
        size='md'
      >
        View Team Details{' '}
      </Button>
    </div>
  )
}

export default TeamInfo
