import Dot from '@/common/Dot'
import Typography from '@/common/Typography'

import ApiState from '@/common/ApiState'
import { useLocation, useParams } from 'react-router'
import { useGetHighlights } from '@/store/startupStore'
import { Colors } from './utils/constants'

const StartupHighlights = () => {
  const { state } = useLocation()
  const startupId = Number(state?.id)

  const { data: allHighlights, isLoading: isLoadingHighlights } =
    useGetHighlights(startupId)
  return (
    <section className='flex w-full p-6 flex-col items-start rounded-lg bg-sifuse-shades-50 gap-4'>
      <header>
        <Typography as='h3' heading='xs'>
          Business Highlights
        </Typography>
      </header>
      <main className='w-full flex'>
        <ApiState
          isLoading={isLoadingHighlights}
          emptyComponent={
            <div className='w-full flex justify-center h-full items-center min-h-[300px]'>
              <Typography
                className='w-full text-center'
                heading='4xs'
                color='#979797'
              >
                No highlights yet
              </Typography>
            </div>
          }
          isEmpty={!allHighlights?.length}
        >
          <ul className='flex flex-col gap-2'>
            {(Array.isArray(allHighlights) ? allHighlights : []).map(
              (highlight, ind) => (
                <HighlightCard
                  key={highlight.id}
                  bgColor={Colors[ind % Colors.length].bgColor}
                  color={Colors[ind % Colors.length].textColor}
                  text={highlight.content}
                />
              )
            )}
          </ul>
        </ApiState>
      </main>
    </section>
  )
}

const HighlightCard = (props: {
  color: string
  bgColor: string
  text: string
}) => {
  return (
    <li
      style={{
        background: props.bgColor,
      }}
      className='flex p-4 items-start gap-2 rounded-lg'
    >
      <Dot
        style={{
          background: props.color,
        }}
        className='mt-2'
      />
      <Typography color='var(--shades800)' button='md'>
        {props.text}
      </Typography>
    </li>
  )
}

export default StartupHighlights
