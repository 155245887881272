const composeEventHandlers = (
  externalHandler?: (e: any) => void,
  internalHandler?: (e: any) => void
) => {
  return (event: React.SyntheticEvent | Event) => {
    externalHandler?.(event)
    if (event.defaultPrevented) return
    internalHandler?.(event)
  }
}

// function compose(/* fns */) {
//   let args = arguments
//   let start = args.length - 1
//   return function () {
//     let i = start
//     const that = this as unknown as {}
//     let result = args[start].apply(that, arguments)
//     while (i--) result = args[i].call(that, result)
//     return result
//   }
// }

export default composeEventHandlers
