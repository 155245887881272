export const SIGNIN = '/users/login/'
export const ACCEPT_INVITE = '/startups/invites/accept/'
export const SIGNUP = '/users/signup/'

export const REQUEST_PRESIGNED_URL = '/users/presigned-urls/'
export const FUND_ENTITY = '/funds/entity/'
export const VERIFY_ACCOUNT = '/users/verify-email/'
export const RESEND_VERIFICATION_LINK = '/users/resend-verification-email/'
export const REFRESH = '/users/refresh/'
export const LOGOUT = '/users/logout/'
export const FORGOT_PASSWORD = '/users/forgot-password/'
export const RESET_PASSWORD = '/users/reset-password/'
export const CHANGE_PASSWORD = '/users/change-password/'

export const GROUP_MEMBERS = '/users/investors/groups/'
export const JOIN_USER_GROUPS = '/users/investors/groups/members/'
export const GET_INVESTORS_GROUP = '/users/investors/'
export const GET_GROUPS = '/users/investors/groups/'

export const GET_ANGEL_INVESTORS = '/users/investors/'
export const GET_SYNDICATE_INVESTORS = '/users/investors/groups/'

export const STARTUPS = '/startups/'
export const STARTUP_FUNDING = '/startups/funding/'
export const STARTUP_ASSESSMENT = '/startups/assessments/'
export const STARTUP_ASSESSMENT_DATA = '/startups/assessments/data/'
export const TEAM_MEMBER = '/startups/members/'
export const MEMBER_INVITE = '/startups/invites/'

export const HIGHLIGHTS = '/startups/highlights/'
export const USERS = '/users/'

export const INDUSTRIES = '/startups/industries/'
export const COUNTRIES = '/startups/countries/'
export const PRODUCT_TYPES = '/startups/product-types/'

// network module apis
export const DENY_CONNECTION = '/networks/connections/'
export const GET_POSTS = '/networks/posts/'

export const CHATS = '/chats/'
export const MARK_CHATS_READ = '/chats/mark-all-as-read/'
export const CHATS_MESSAGES = '/chats/messages/'
export const UNREAD_COUNT = '/chats/messages/unread-count/'
export const CHAT_MEDIA = '/chats/media/'
export const SHARED_MEDIA = '/chats/shared-media/'
export const SHARED_DOCUMENT = '/chats/shared-documents/'
export const SHARED_LINK = '/chats/shared-links/'

export const GET_STARTUPS = '/startups/'
export const GET_STARTUPS_HIGHLIGHTS = '/startups/highlights/'
export const GET_USERS = '/users/'
export const GET_STARTUPS_THAT_MATCH_CRITERIA = '/startups/assessments/matches/'

export const SEARCH_TIMELINE = '/networks/timeline/search/'
export const TRENDING_STARTUPS = '/networks/timeline/startup-suggestions/'
export const RECOMMENDED_INVESTORS = '/networks/timeline/investor-suggestions/'
export const CONNECTIONS = `/networks/connections/`
export const POSTS = `/networks/posts/`
export const GET_TIMELINE_ACTIVITIES = `/networks/timeline/activity/`
export const POST_MEDIA = `/networks/posts/media/`
export const POST_COMMENT = `/networks/posts/comments/`
export const MENTIONS = `/networks/timeline/mentions/`

export const NOTIFICATION = '/notifications/'
export const MARK_NOTIFICATION_AS_READ = '/notifications/mark-all-as-read/'
export const GET_UNREAD_NOTIFICATION = '/notifications/unread-count/'
export const REGISTER_DEVICE = '/notifications/webpush/'

/**
 * Community
 */
export const GET_COMMUNITIES = `/communities/`
export const GET_COMMUNITY_MEMBERS = `/communities/members/`
/***
 *
 * community - Feeds
 */
export const GET_COMMUNITY_UPDATES = '/communities/feeds/'
export const POST_COMMUNITY_UPDATES_MEDIA = '/communities/feeds/media/'
export const GET_COMMUNITY_UPDATES_COMMENTS = '/communities/comments/'

export const COMMUNITY_GROUPS = '/communities/groups/'
export const COMMUNITY_GROUPS_MEMBERS = '/communities/groups/members/'
export const COMMUNITY_GROUPS_MESSAGES = '/communities/groups/messages/'
export const GROUPS_MESSAGES_MEDIA = '/communities/groups/messages/media/'

// Data room
export const GET_DATA_ROOM = '/data-rooms/documents/startups/'
export const GET_FOLDERS = '/data-rooms/documents/folders/'
export const GET_FUND_FOLDERS = '/funds/documents/folders/'
export const GET_FILES = '/data-rooms/documents/files/'
export const GET_FUND_FILES = '/funds/documents/files/'
export const CREATE_FUND_DOCUMENT = '/funds/documents/'
export const CREATE_DOCUMENT = '/data-rooms/documents/'
export const SEARCH_DOCUMENTS = '/data-rooms/documents/search/'
export const SEARCH_FUND_DOCUMENTS = '/funds/documents/search/'
/***
 *
 * Updates tab
 */
export const GET_UPDATES = '/data-rooms/updates/'
export const POST_UPDATES_MEDIA = '/data-rooms/updates/media/'
export const GET_UPDATES_COMMENTS = '/data-rooms/updates/comments/'
/***
 *
 * access tab
 */
export const GET_ACCESS_LIST = '/data-rooms/access/'

//socket
export const WEB_SOCKET_POST = `wss://api.sifuse.heckerbellaapps.com/ws/post/`
export const SIFUSE_FRONTEND_URL = `https://business.sifuse.com`
// export const SIFUSE_FRONTEND_URL = `http://localhost:3000`

export const EVENTS = '/events/'
export const TOP_EVENTS = '/events/top-three/'

// funds

export const FUNDS_OVERVIEW = '/funds/organisation-overview/'
export const FUNDS = '/funds/'
export const FUND_INVESTORS = '/funds/investors/'
export const FUND_INVESTOR_CAPITAL_CALL = '/funds/investors/capital-call/'

export const FUNDS_PORTFOLIO = '/funds/portfolios/'
export const FUNDS_PORTFOLIO_VALUATIONS = '/funds/portfolios/valuations/'
export const FUNDS_PORTFOLIO_KPI = '/funds/portfolios/kpi/'
export const FUNDS_EXIT_STARTUP = '/funds/portfolios/exit/'
export const FUNDS_MEMBERS = '/funds/portfolios/members/'
export const FUNDS_INVESTMENT = '/funds/portfolios/investments/'
export const FUNDS_UPDATES = '/funds/updates/'
export const FUNDS_ORGANISATIONS = '/funds/organisations/'
export const FUNDS_CURRENCIES = '/funds/currencies/'
export const PORTFOLIO_INVESTMENTS = '/funds/portfolios/investments/'
export const INDIVIDUAL_FUND_INVESTOR = '/funds/investors/individuals/'
export const ORGANISATION_FUND_INVESTOR = '/funds/investors/organisations/'
export const FUNDS_DOCUMENTS = '/funds/documents/'

export const ADMIN_USERS = '/funds/admins/'
export const ADMIN_ROLES = '/funds/admins/roles/'
export const PERMISSIONS = '/funds/admins/permissions/'

/***
 *
 * Investor management
 */
export const GET_INVESTORS = '/funds/investors/'

export const DEALS = '/deals/'
export const DEALS_REORDER = '/deals/reorder/'
export const DEAL_STAGES = '/deals/stages/'
export const DEAL_STAGES_REORDER = '/deals/stages/reorder/'
export const GET_SCREENING_CRITERIALS = '/deals/screening-criteria/'
export const CREATE_CATEGORY = '/deals/screening-criteria/categories/'
export const SCREEN_DEALS = '/deals/screening-criteria/screen-deals/'
export const SCREENED_DEALS = '/deals/screening-criteria/screened-deals/'
export const ADD_DEAL_TO_FUND = '/deals/add-to-fund/'
