import iconComponents from '@/assets/icons/iconComponents'
import { MenuItem } from './dashboard.types'
import { Pages } from '@/routes/route.constant'
import Chats from './components/header/Chats'

export const MenuLists: MenuItem[] = [
  {
    name: 'Dashboard',
    icon: iconComponents.navigation.DashboardIcon,
    url: `/app/${Pages.Dashboard}`,
    hasPermission: false,
  },
  {
    name: 'Business Discovery',
    icon: iconComponents.navigation.StartupDiscoveryIcon,
    url: `/app/${Pages.StartupDiscovery}`,
    hasPermission: false,
  },
  {
    name: 'The Network',
    icon: iconComponents.navigation.NetworkIcon,
    url: `/app/${Pages.Network}`,
    hasPermission: false,
  },
  {
    name: 'Portfolio',
    icon: iconComponents.navigation.FileIcon,
    url: `/app/${Pages.Portfolio}`,
    hasPermission: false,
  },
  {
    name: 'Deal Flow',
    icon: iconComponents.navigation.DealFlowIcon,
    url: `/app/${Pages.DealFlow}`,
    hasPermission: false,
  },
  {
    name: 'Investor Management',
    icon: iconComponents.navigation.MoneySendIcon,
    url: `/app/${Pages.InvestorManagement}`,
    hasPermission: false,
  },
  {
    name: 'Events',
    icon: iconComponents.navigation.EventIcon,
    url: '/app/events',
    hasPermission: false,
  },
  {
    name: 'Community',
    icon: iconComponents.navigation.TeamIcon,
    url: `/app/${Pages.Community}`,
    hasPermission: false,
  },

  {
    name: 'Message',
    icon: Chats,
    url: `/app/${Pages.Message}`,
    hasPermission: false,
  },

  {
    name: 'User Roles & Permissions',
    icon: iconComponents.navigation.RolesIcon,
    url: `/app/${Pages.UsersRoles}`,
    hasPermission: false,
  },
  {
    name: 'Profile',
    icon: iconComponents.navigation.ProfileIcon,
    url: `/app/${Pages.Profile}`,
    hasPermission: false,
  },
]

export const OrganisationMenuLists: MenuItem[] = [
  {
    name: 'Funds',
    icon: iconComponents.navigation.FundIcon,
    url: `/app/${Pages.Fund}`,
    hasPermission: false,
  },
  {
    name: 'Profile',
    icon: iconComponents.navigation.ProfileIcon,
    url: `/app/${Pages.OrganisationProfile}`,
    hasPermission: false,
  },
]
