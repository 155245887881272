import api from './api'
import { ApiRequestConfig, ServerRes } from './api.types'
import { FundInfoReqDTO, FundInfoResDTO, PersonalInfoReqDTO } from './authApis'
import {
  CHANGE_PASSWORD,
  FUND_ENTITY,
  REQUEST_PRESIGNED_URL,
  STARTUPS,
  USERS,
} from './endpoints'
import { FILES_URL } from '@/constants/api'

export type ChangePasswordReqDTO = {
  new_password: string
  old_password: string
}

export type RequestPresignedUrlReqDTO = {
  action: 'PUT' | 'DELETE'
  module_name: string
  files: {
    name: string
    type?: string
  }[]
}

export type PutFiles = {
  module_name: string
  files: File[]
}

type RequestPresignedUrlResDTO = {
  action: 'PUT' | 'DELETE'
  signed_urls: string[]
}
export const ChangePassword = async (data: ChangePasswordReqDTO) => {
  return await api.post<ServerRes<any>>(CHANGE_PASSWORD, data)
}
export const GetFundEntity = async (id: number) => {
  return (await api.get<ServerRes<FundInfoResDTO>>(FUND_ENTITY + id + '/')).data
    .data
}

const requestPresignedUrl = async (data: RequestPresignedUrlReqDTO) => {
  return (
    await api.post<ServerRes<RequestPresignedUrlResDTO>>(
      REQUEST_PRESIGNED_URL,
      data
    )
  ).data.data
}

export const PutFilesToStore = async (
  data: PutFiles,
  configs?: ApiRequestConfig[]
) => {
  const { signed_urls, action } = await requestPresignedUrl({
    module_name: data.module_name,
    action: 'PUT',
    files: data.files.map((file) => ({
      name: file.name,
      type: file.type,
    })),
  })
  if (action !== 'PUT') {
    throw new Error('Bad Url Response')
  }

  const results = await Promise.all(
    signed_urls.map((url, i) => {
      return api.put(url, data.files[i], {
        ...(configs?.length ? configs[i] : {}),
        // url,
        // method: 'PUT',
        baseURL: '',
        withCredentials: false,
        ignoreAuthentication: true,
        headers: {
          'Content-Type': data.files[i].type,
        },
      })
    })
  )

  return results.map((res, i) => {
    const Url = new URL(signed_urls[i])

    return { url: FILES_URL + Url.pathname }
  })
}
export const DeleteFilesFromStore = async (
  data: Omit<RequestPresignedUrlReqDTO, 'action'>
) => {
  const { signed_urls } = await requestPresignedUrl({
    action: 'DELETE',
    ...data,
  })
  return (
    await Promise.all(
      signed_urls.map((url) => fetch(url, { method: 'DELETE' }))
    )
  ).map((res) => res)
}

export const UpdateFundEntity = async (
  data: Partial<FundInfoReqDTO> & { id: number }
) => {
  const { id, ...rest } = data

  return await api.patch<ServerRes<FundInfoResDTO>>(
    FUND_ENTITY + `${id}/`,
    rest
  )
}

export const RemoveCompanyLogo = async (data: { startup: number }) => {
  return await api.patch<ServerRes<any>>(
    STARTUPS + `${data.startup}/remove-logo/`,
    {}
  )
}

export const UpdateUser = async (data: FormData) => {
  const id = data.get('id')
  data.delete('id')
  return await api.patch<ServerRes<PersonalInfoReqDTO>>(USERS + `${id}/`, data)
}

export const RemoveUserAvatar = async (data: { user: number }) => {
  return await api.patch<ServerRes<any>>(
    USERS + `${data.user}/remove-avatar/`,
    {}
  )
}
