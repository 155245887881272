import { toastEmitter } from './emitter'
import { NotifyConfig, NotifyTypes } from './toast.types'

function ToastNotify(type: NotifyTypes, config: NotifyConfig) {
  toastEmitter.emit('increment', {
    type,
    ...config,
  })
}

export default ToastNotify
