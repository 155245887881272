import iconComponents from '@/assets/icons/iconComponents'
import useGetCssValue from '@/hooks/useGetCssValue'
import { useOutsideClick } from '@/hooks/useOutsideClick'
import { useToggleState } from '@/hooks/useToggleState'

import { useMemo, useRef } from 'react'

import { MenuItem } from '../../dashboard.types'

import NavItem from '../NavItem'
import UserInfo from '../UserInfo'
import Image from '@/common/Image'
import useWindowSize from '@/hooks/useWindowSize'
import { Pages } from '@/routes/route.constant'
import Notifications from '../header/Notifications'
import Permission from '@/common/permissions/Permission'
import { PermissionNames } from '@/common/permissions/permission.constant'

type TabletMenuProps = {
  menuOptions: MenuItem[]
}
const TabletMenu = (props: TabletMenuProps) => {
  const { menuOptions } = props
  const { width } = useWindowSize()
  const [navRef, navWidth] = useGetCssValue('width', '400px', width)

  const {
    state: showMore,
    toggle: toggleMore,
    close: closeMore,
  } = useToggleState(false)
  const moreRef = useRef<HTMLDivElement>(null)

  useOutsideClick([moreRef], () => closeMore())
  const allOptions = [
    ...menuOptions,

    {
      icon: Notifications,
      name: 'Notifications',
      url: `/app/${Pages.Notification}`,
      hasPermission: false,
    },
    // {
    //   name: 'Settings',
    //   icon: iconComponents.navigation.SettingIcon,
    //   url: `/app/setting`,
    // },
  ] as const
  const howManyElement = useMemo(() => {
    const logoWidth = 56
    const moreAndUserInfoWidth = 246
    const gapsBetween = 32 * 2
    const spacing = logoWidth + moreAndUserInfoWidth
    const bodyWidth = width
    const NavItemSize = 24 + 16 * 2 + 8
    return Math.floor(
      parseInt(navWidth) / NavItemSize ||
        (bodyWidth - spacing - gapsBetween) / NavItemSize
    )
  }, [width, navWidth])

  return (
    <section className='w-full   justify-between items-center gap-8 py-[22px] z-10 hidden md:flex xl:hidden md:border-b'>
      <header className='z-[100]'>
        <Image
          src='/logo.png'
          className='min-w-[53px] w-[56px] min-h-[52px] h-[52px] px-2'
        />
      </header>
      <nav className='flex flex-1 z-[100]' ref={navRef}>
        <ul role='menu' className='flex gap-4 items-center w-full'>
          {allOptions.slice(0, howManyElement).map((menu) =>
            menu.hasPermission === true ? (
              <Permission
                key={menu.name}
                noAccess={<></>}
                permissions={[
                  PermissionNames[menu.permissionKey as 'Can View Message'],
                ]}
              >
                <NavItem key={menu.name} {...menu} />
              </Permission>
            ) : (
              <NavItem key={menu.name} {...menu} />
            )
          )}
        </ul>
      </nav>
      <footer className='flex justify-between w-[246px] items-start justify-self-end z-[100]'>
        {allOptions.length > howManyElement && (
          <div className='relative' ref={moreRef}>
            <NavItem
              name='More'
              icon={iconComponents.util.MoreHorizIcon}
              onClick={() => toggleMore()}
              hasPermission={false}
            />
            {showMore && (
              <ul
                onClick={closeMore}
                className='flex flex-col gap-2 fixed !z-50 top-[80px]  mx-8 left-0  w-[calc(100svw_-_64px)] bg-sifuse-shades-50 items-center px-4 py-2 rounded-lg h-auto shadow-sm'
              >
                {allOptions.slice(howManyElement).map((menu) => {
                  return menu.hasPermission ? (
                    <Permission
                      key={menu.name}
                      noAccess={<></>}
                      permissions={[
                        PermissionNames[
                          menu.permissionKey as 'Can View Message'
                        ],
                      ]}
                    >
                      <NavItem key={menu.name} showText {...menu} />
                    </Permission>
                  ) : (
                    <NavItem key={menu.name} showText {...menu} />
                  )
                })}
              </ul>
            )}
          </div>
        )}
        <UserInfo />
      </footer>
    </section>
  )
}

export default TabletMenu
