import { ApiStatus } from '@/apis/constants/apiStatus'
import { useAuthContext } from '@/contexts/AuthContextProvider'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import JSZip from 'jszip'
import { useState, useCallback } from 'react'

const useDownload = () => {
  const { getAccessToken } = useAuthContext()
  const token = getAccessToken()

  const [isDownloading, setIsDownloading] = useState(false)
  const [doneDownloading, setDoneDownloading] = useState(false)
  const exportHandler = useCallback(
    async (
      endpoint: string,
      options: { file_type?: string; file_name?: string }
    ) => {
      setIsDownloading(true)
      setDoneDownloading(false)
      fetch(endpoint, {
        method: 'GET',
        headers: {
          authorization: token ? `Bearer ${token || ''}` : '',
          'Content-Type': 'application/json',
          // "accept": "text/plain"
        },
        // credentials: 'include',
      })
        .then((res) => {
          return res.blob()
        })
        .then((str) => {
          var url = window.URL || window.webkitURL
          var link = url.createObjectURL(str)
          var a = document.createElement('a')
          a.setAttribute(
            'download',
            `${options.file_name || 'downloaded at' + new Date().getTime()}${
              options.file_type ? '.' + options.file_type : ''
            }`
          )
          a.setAttribute('href', link)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          window.URL.revokeObjectURL(link)
          setIsDownloading(false)
          setDoneDownloading(true)
        })
        .catch((err) => {
          setIsDownloading(false)
          setDoneDownloading(false)
        })
    },
    []
  )
  return {
    exportHandler,
    isDownloading,
    doneDownloading,
  }
}

export const useDownloadFileFromStore = () => {
  const [downloadState, setDownloadState] = useState<ApiStatus>('IDLE')

  const exportHandler = useCallback(
    async (
      endpoint: string,
      options: { file_type?: string; file_name?: string }
    ) => {
      setDownloadState('PENDING')

      fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // "accept": "text/plain"
        },
      })
        .then((res) => {
          return res.blob()
        })
        .then((str) => {
          var url = window.URL || window.webkitURL
          var link = url.createObjectURL(str)
          var a = document.createElement('a')
          a.setAttribute(
            'download',
            `${options.file_name || 'downloaded at' + new Date().getTime()}${
              options.file_type ? '.' + options.file_type : ''
            }`
          )
          a.setAttribute('href', link)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          window.URL.revokeObjectURL(link)
          setDownloadState('SUCCESS')
        })
        .catch((err) => {
          setDownloadState('ERROR')
        })
    },
    []
  )
  return {
    exportHandler,
    downloadState,
  } as const
}

type StatusTracker = {
  loaded: number
  total: number
  progress: number
}
export const useDownloadMultipleFilesFromStoreAsZip = () => {
  const [downloadState, setDownloadState] = useState<ApiStatus>('IDLE')
  const [tracker, setTracker] = useState<StatusTracker[]>([])
  const exportHandler = useCallback(
    async (
      fileData: {
        url: string
        options?: { file_type?: string; file_name?: string }
      }[],
      options?: {
        onDownloadProgress?: (statuses: StatusTracker) => void
        file_name?: string
      }
    ) => {
      setDownloadState('PENDING')
      async function downloadFileWithProgress(
        url: string,
        progressCallback?: (status: StatusTracker) => void
      ) {
        const response = await fetch(url)

        if (!response.ok) {
          throw new Error(`Failed to fetch ${url}: ${response.statusText}`)
        }

        const contentLength = parseInt(
          response.headers.get('Content-Length') || '0'
        )
        const reader = response.body!.getReader()
        let receivedLength = 0 // Keep track of how much we've received

        const chunks = []
        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          }
          chunks.push(value)
          receivedLength += value.length

          if (contentLength) {
            const progress = (receivedLength / contentLength) * 100
            progressCallback?.({
              loaded: receivedLength,
              total: contentLength,
              progress,
            })
          }
        }

        // Concatenate all chunks into one array
        let chunksAll = new Uint8Array(receivedLength)
        let position = 0
        for (let chunk of chunks) {
          chunksAll.set(chunk, position)
          position += chunk.length
        }

        return chunksAll.buffer
        // const response = await axios.get(url, {
        //   responseType: 'arraybuffer', // Ensure that the response is a binary buffer
        //   onDownloadProgress: (event) => {
        //     if (event.lengthComputable) {
        //       const { loaded, total = 0 } = event
        //       const progress = (loaded / total) * 100
        //       progressCallback?.({ progress, loaded, total })
        //     }
        //   },
        // })

        // return response.data
      }
      const zip = new JSZip()

      const { error, response: resData } = await withAsync(() =>
        Promise.all(
          fileData.map((file, idx) =>
            downloadFileWithProgress(file.url, (progress) => {
              setTracker((prev) => {
                const newArray = [...prev]
                newArray[idx] = progress
                if (newArray.length === fileData.length) {
                  const allFilesTracking = newArray.reduce((acc, cur) => {
                    acc.progress += cur.progress
                    acc.loaded += cur.loaded
                    acc.total += cur.total
                    return acc
                  })
                  options?.onDownloadProgress?.(allFilesTracking)
                }
                return newArray
              })
            })
          )
        )
      )

      if (error) {
        return errorHandler(error)
      }
      if (!resData) {
        setDownloadState('ERROR')
      }
      for (let i = 0; i < fileData.length; i++) {
        const file = fileData[i]
        const fileName = file.options?.file_name || file.url.split('/').pop()

        zip.file(fileName!, resData![i])
      }
      await zip
        .generateAsync({ type: 'blob' })
        .then(function (content) {
          var url = window.URL || window.webkitURL
          var link = url.createObjectURL(content)
          var a = document.createElement('a')
          a.setAttribute(
            'download',
            `${
              options?.file_name || 'downloaded at' + new Date().getTime()
            }.zip`
          )
          a.setAttribute('href', link)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          window.URL.revokeObjectURL(link)
          setDownloadState('SUCCESS')
        })
        .catch((err) => {
          setDownloadState('ERROR')
        })
    },
    []
  )
  return {
    exportHandler,
    downloadState,
    tracker,
  } as const
}
export default useDownload
