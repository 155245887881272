import { useLocation, useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import useRefreshToken from './useRefreshToken'
import { axiosInstance } from '../api'
import { HttpStatusCode } from '../constants/statusCode'
import { withAsync } from '@/helpers/withAsync'
import { useAuthContext } from '@/contexts/AuthContextProvider'
import { FILES_URL } from '@/constants/api'

const useAutomaticRefresh = () => {
  const [isDone, setIsDone] = useState(false)

  const { getAccessToken } = useAuthContext()

  const refresh = useRefreshToken()
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    const requestInterceptors = axiosInstance.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          if (getAccessToken())
            config.headers['Authorization'] = `Bearer ${getAccessToken()}`

          if (
            config.url?.includes('users/refresh/') ||
            config.url?.includes(FILES_URL!)
          ) {
            delete config.headers['Authorization']
          }
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    const responseInterceptors = axiosInstance.interceptors.response.use(
      (response) => {
        return response
      },
      (() => {
        let sent = false
        return async (error) => {
          const prevRequest = error?.config

          if (
            (error?.response?.status === HttpStatusCode.Forbidden ||
              error?.response?.status === HttpStatusCode.Un_Authorized) &&
            !prevRequest?.sent &&
            !sent
          ) {
            prevRequest.sent = true
            sent = true
            const { error, response: newAccessToken } = await withAsync(() =>
              refresh()
            )
            if (error) {
              return Promise.reject(error)
            }
            prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
            return axiosInstance(prevRequest)
          } else if (
            (error?.response?.status === HttpStatusCode.Forbidden ||
              error?.response?.status === HttpStatusCode.Un_Authorized) &&
            sent
          ) {
            prevRequest.sent = false
            sent = false
            navigate(`/auth/signin/`, {
              state: {
                from: location.pathname,
              },
            })
          }

          return Promise.reject(error)
        }
      })()
    )
    setIsDone(true)
    return () => {
      axiosInstance.interceptors.response.eject(responseInterceptors)
      axiosInstance.interceptors.request.eject(requestInterceptors)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessToken])
  return [axiosInstance, isDone] as const
}

export default useAutomaticRefresh
