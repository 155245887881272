export const generateId = () => Math.random().toString(36).substring(2, 10)
export const exportStyleFromClassName = (className: string) => {
  const pattern = /(top-|left-|bottom-|right-)([^\s]+)/g
  const obj: Partial<Record<'top' | 'left' | 'bottom' | 'right', string>> = {}
  className.replace(
    pattern,
    function (substring, positionEle: string, positionValue: string) {
      const key: keyof typeof obj = positionEle.replace(
        '-',
        ''
      ) as keyof typeof obj
      obj[key] = positionValue.replace(/[\[\]]/g, '').replaceAll('_', ' ')
      return substring
    }
  )
  return obj
}
