import { CHATS, CHATS_MESSAGES, UNREAD_COUNT } from '@/apis/endpoints'
import iconComponents from '@/assets/icons/iconComponents'
import { WEB_SOCKET_URL } from '@/constants/api'
import { useAuthContext } from '@/contexts/AuthContextProvider'
import useMediaQuery from '@/hooks/useMediaQuery'
import { Pages } from '@/routes/route.constant'
import { useGetUnreadCount } from '@/store/chatStore'
import clsx from 'clsx'

import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router'

import { w3cwebsocket as W3CWebSocket } from 'websocket'

const Chats = () => {
  const navigate = useNavigate()
  const { getAccessToken } = useAuthContext()
  const queryClient = useQueryClient()
  const token = getAccessToken()
  const { pathname } = useLocation()
  const { data: unreadCount } = { data: { unread_count: 0 } } //useGetUnreadCount()
  const isMobile = useMediaQuery('(max-width: 760px)')
  useEffect(() => {
    let client
    try {
      client = new W3CWebSocket(`${WEB_SOCKET_URL}/ws/chat/${token}/`)
    } catch (error: any) {
      console.log(error.message, "Couldn't create connection")
    }
    if (!client) return
    client.onopen = () => {
      console.log('WebSocket Client Connected TO chats')
    }
    client.onmessage = (ev: any) => {
      let dataFromServer
      console.log(ev.data, 'RECEIVED')
      try {
        dataFromServer = JSON.parse(ev.data)
        if (dataFromServer) {
          const { action } = dataFromServer
          console.log(dataFromServer, 'DATA')
          if (action === 'message' || action === 'read') {
            queryClient.invalidateQueries(CHATS)
            queryClient.invalidateQueries(CHATS_MESSAGES)
            queryClient.invalidateQueries(UNREAD_COUNT)
          }
        }
      } catch (err) {
        console.log(ev.data, 'DATA FROM SERVER')
      }
    }
  }, [token, queryClient])
  const isMessages = pathname.includes(`app/${Pages.Message}`)
  return (
    <span
      onClick={() => navigate(`/app/${Pages.Message}`)}
      className='flex gap-3 items-center cursor-pointer relative'
    >
      <iconComponents.navigation.MessageIcon />
      {!!unreadCount?.unread_count && (
        <span
          className={clsx(
            'inline-flex justify-center items-center gap-0.5 rounded-full w-[18px] h-[18px] border-2 border-sifuse-shades-50 bg-[var(--mainred)] absolute -top-2 -right-2',
            isMessages && !isMobile && '!border-sifuse-main-primary',
            'text-sifuse-shades-50 text-center text-[8px] font-medium leading-[11px]'
          )}
        >
          {unreadCount?.unread_count > 9 ? `+9` : unreadCount?.unread_count}
        </span>
      )}
    </span>
  )
}

export default Chats
