import { imagesUrl } from '@/assets/images/imageUrls'
import Typography from '@/common/Typography'
import { truncateText } from '@/helpers/ellipsizeTextBox'
import clsx from 'clsx'
import React from 'react'
import { useNavigate } from 'react-router'
interface Props {
  src: string
  name: string
  title?: string
  userId: number
  company?: string
  navigateTo?: string
  navigateState?: Record<string, any> | null
  clickable?: boolean
  isGroup?: boolean
}
function PosterDetails({
  src,
  name,
  title,
  userId,
  company,
  navigateTo,
  navigateState,
  clickable = true,
  isGroup = false,
}: Props) {
  const navigate = useNavigate()

  const handleClick = () => {
    if (!clickable) return
    navigate(navigateTo ? navigateTo : `/app/networks/user/${name}`, {
      state: navigateState ? navigateState : { id: userId, from: 'startups' },
    })
  }
  return (
    <div className='w-fit flex items-center gap-2.5'>
      <img
        className={clsx(
          'rounded-full w-[50px] h-[50px] object-bottom object-cover',
          {
            '!rounded-sm': isGroup,
          }
        )}
        alt={name + ' poster'}
        src={
          src || isGroup ? imagesUrl.blanckCompImg : imagesUrl.blanckProfileImg
        }
      />
      <div
        className={clsx(
          `flex-col flex gap-0.5 ${clickable ? 'cursor-pointer' : ''} `
        )}
        onClick={handleClick}
      >
        <Typography heading='3xs' className='capitalize'>
          {name}
        </Typography>
        {title && (
          <span className='flex gap-1'>
            {title && <p className='opacity-50'> {truncateText(title, 10)} </p>}
            {company && (
              <strong className='!text-[#6D6D77]'>
                @{truncateText(company, 4)}
              </strong>
            )}
          </span>
        )}
      </div>
    </div>
  )
}

export default PosterDetails
