import { imagesUrl } from '@/assets/images/imageUrls'
import Button from '@/common/button/Button'
import TextInput from '@/common/TextInput'
import Typography from '@/common/Typography'
import { ReactComponent as SearchIcon } from '@/assets/network/search-normal.svg'

import React from 'react'
import { FaCircle, FaDotCircle, FaSearch } from 'react-icons/fa'
import LoadingState from './LoadingState'
import { FaX } from 'react-icons/fa6'
import clsx from 'clsx'

interface ItemObj {
  about?: string
  avatar: string
  name: string
  online?: boolean

  id?: number
  startup: { id: number; name: string; logo: string } | null
}

interface Props {
  list?: ItemObj[]
  onClickItem?: (item: Record<string, any>) => void
  title: string
  search?: string
  setsearch?: (e: any) => void
  onSubmitSearch?: () => void
  showSearch?: boolean
  isModal?: boolean
}

interface ItemProps {
  item?: ItemObj

  onClickItem?: (item: Record<string, any>) => void
}

const Item = ({ onClickItem, item }: ItemProps) => {
  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center gap-1'>
        <span
          style={{
            background: `url(${
              (item?.startup ? item?.startup?.logo : item?.avatar) ||
              imagesUrl.blanckProfileImg
            }) no-repeat center center/cover`,
            zIndex: 0,
          }}
          className='relative rounded-full h-[32px] w-[32px] '
        >
          {item?.online ? (
            <FaCircle
              fontSize={8}
              className='text-green-600 absolute top-0.5 right-0'
            />
          ) : null}
        </span>
        <p>{item?.startup ? item?.startup?.name : item?.name}</p>
      </div>
      <Button
        className='px-5 rounded border border-gray-100'
        size='sm'
        color='white'
        onClick={() => onClickItem && onClickItem(item!)}
      >
        View
      </Button>
    </div>
  )
}

function ListView({
  list,
  onClickItem = () => {},
  title,
  search,
  setsearch,
  showSearch = false,
  onSubmitSearch,
  isModal,
}: Props) {
  return (
    <div
      className={clsx(
        'bg-[white] rounded-lg p-[24px]  flex flex-col gap-4 border border-[#E6E6EB]',
        isModal ? '!border-0' : ''
      )}
    >
      <Typography heading='3xs'>{title}</Typography>
      {showSearch && (
        <TextInput
          placeholder='Search'
          name='search'
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setsearch && setsearch(e.target.value)
          }
          onSubmit={onSubmitSearch}
          startIcon={<SearchIcon />}
          endIcon={
            !!search && (
              <FaX
                className='text-[0.7rem]'
                onClick={() => setsearch && setsearch('')}
              />
            )
          }
          className='w-full border border-gray-200'
        />
      )}

      {list?.length ? (
        list?.map((item) => (
          <Item key={item.id} onClickItem={onClickItem} item={item} />
        ))
      ) : (
        <LoadingState
          condition={'isEmpty'}
          isEmptyDisplay={
            <>
              <Typography heading='3xs'>No content to display</Typography>
            </>
          }
        />
      )}
    </div>
  )
}

export default ListView
